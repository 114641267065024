import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {FormsModule} from "@angular/forms";
import validator from "validator";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import {TooltipModule} from "primeng/tooltip";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {ClipboardHelper, Journal, RegistryService, StringHelper} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-share',
  standalone: true,
  imports: [
    InputGroupModule,
    InputGroupAddonModule,
    FormsModule,
    TooltipModule,
    ButtonModule,
    InputTextModule,
    NgIf
  ],
  templateUrl: './journal-share.component.html',
  styleUrl: './journal-share.component.scss'
})
export class JournalShareComponent implements OnInit {
  permalink = '';

  get url() {
    return `https://törn.link/${this.journal?.permalink}`;
  }

  @Input() journal?: Journal;
  @Output() close = new EventEmitter();

  constructor(private alertService: AlertModalService,
              private loader: LoaderService,
              private stringHelper: StringHelper,
              private clipboardHelper: ClipboardHelper,
              private services: RegistryService) {
  }

  async ngOnInit() {
    while (this.journal === undefined) {
      await new Promise(r => setTimeout(r, 200));
    }
    this.permalink = this.journal.permalink ?? '';
  }

  async generatePermalink() {
    if (this.journal === undefined) {
      return;
    }
    this.permalink = this.stringHelper.generateRandomAlfaString(6).toLowerCase();
    await this.share();
  }

  async share() {

    if (this.journal == undefined) {
      return;
    }

    if (this.permalink != '' && !validator.isAlpha(this.permalink?.toString())) {
      this.alertService.show('Der angegebene Link darf nur aus alphabetischen Zeichen bestehen.');
      return;
    }

    try {
      this.loader.visibility = true;
      if (this.permalink == undefined || this.permalink == '') {
        this.permalink = this.stringHelper.generateRandomAlfaString(6).toLowerCase();
      }
      await this.services.tracks.setPermalink(this.journal?.id, this.permalink);
      this.journal.permalink = this.permalink;
    } catch (e) {
      this.permalink = '';
      this.journal.permalink = undefined;
    } finally {
      this.loader.visibility = false;
    }
  }

  async deletePermalink() {
    try {
      this.loader.visibility = true;
      await this.services.tracks.deletePermalink(this.journal!.id);
      this.journal!.permalink = undefined;
      this.permalink = '';
    } finally {
      this.loader.visibility = false;
    }
  }

  copyPermalink() {
    this.clipboardHelper.copyTextToClipboard(this.url);
    this.alertService.show('Die URL wurde in die Zwischenablage kopiert.', 6000, AlertType.Success);
  }

  async shareLink() {
    const data = {
      title: `Törn-Bericht von: ${this.journal?.title ?? this.journal?.revier ?? this.journal?.country ?? 'k.A.'}`,
      text: `Unter diesem Link ist der Törn-Bericht von: ${this.journal?.title ?? this.journal?.revier ?? this.journal?.country ?? 'k.A.'} zu finden.`,
      url: this.url
    };
    if (navigator.share && navigator.canShare(data)) {
      await navigator.share(data);
    } else {
      this.copyPermalink();
    }
  }

  createMailTemplate() {
    window.open(`mailto:?subject=Törn-Bericht von: ${this.journal?.title ?? this.journal?.revier ?? this.journal?.country ?? 'k.A.'}&body=${this.url}`);
  }

}
