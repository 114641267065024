import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MapComponent} from "../map/map.component";
import {MapService} from "../map/map.service";
import {Map} from 'ol';
import {Coordinate} from "ol/coordinate";
import VectorLayer from "ol/layer/Vector";
import {Control} from "ol/control";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import moment from "moment/moment";
import {LoaderService} from "../loader/loader.service";
import {FormsModule} from "@angular/forms";
import {CreationComponent} from "../journal/creation/creation.component";
import {JournalStatsComponent} from "../journal-stats/journal-stats.component";
import {transform} from "ol/proj";
import {TrackRecordDataComponent} from "../track-record-data/track-record-data.component";
import {TrackRecordDetailsComponent} from "../track-record-details/track-record-details.component";
import {
  Journal,
  JournalEntity,
  JournalStats,
  SocketChannelEvents,
  Track,
  TrackDataKey,
  TrackRecord,
  BaseService,
  ExtractDataService,
  TracksService,
  JournalService
} from "logbuch-client-sdk";
import {AppService} from "../app.service";

@Component({
  selector: 'app-track',
  standalone: true,
  imports: [
    MapComponent,
    NgForOf,
    NgIf,
    FormsModule,
    CreationComponent,
    NgClass,
    JournalStatsComponent,
    TrackRecordDataComponent,
    TrackRecordDetailsComponent,
  ],
  providers: [
    MapService
  ],
  templateUrl: './track.component.html',
  styleUrl: './track.component.scss'
})
export class TrackComponent implements OnInit {

  @Input()
  trackId: number = -1;

  @Input()
  gatewayId: number = -1;

  track?: Track;
  stats?: JournalStats;
  dataKeys: TrackDataKey[] = [];
  map?: Map;
  mapLoaded = false;

  journals: Journal[] = [];
  journal?: Journal;
  journalEntity?: JournalEntity;
  journalId: number = -1;
  creationModal = false;

  startIcon?: VectorLayer<any>;
  endIcon?: VectorLayer<any>;

  coords: Coordinate[] = [];
  selectedRecord?: TrackRecord;

  constructor(private route: ActivatedRoute,
              private trackService: TracksService,
              private journalService: JournalService,
              private base: BaseService,
              private extractData: ExtractDataService,
              private mapService: MapService,
              private loader: LoaderService,
              private appService: AppService) {
    this.route.params.subscribe((params: any) => {
      this.trackId = params.track ?? -1;
      this.gatewayId = params.gateway ?? -1;
    });
  }

  async ngOnInit() {
    this.loader.visibility = true;
    this.base.selectedGateway = this.gatewayId;
    try {
      this.dataKeys = await this.trackService.getTrackDataKeys();
      await this.extractData.init();
      await this.loadTrack();
      // this.drawCompleteTrack();
    } catch (e) {
      console.log(e);
    }
    this.loader.visibility = false;
  }

  async loadJournals(id: number = -1) {
    try {
      this.journals = await this.journalService.getJournals();
      if (id > 0) {
        this.journalId = id;
      }
    } catch (e) {
      //
    }
  }

  async loadTrack() {

    this.appService.websocket.client.on(SocketChannelEvents.TrackRecords, (records: TrackRecord[]) => {
      this.track?.records.push(...records);
      if (records.length <= 0) {
        this.drawCompleteTrack();
      }
    });

    try {
      this.track = await this.trackService.getTrack(this.trackId, true);
      this.track.records = [];
      this.stats = await this.trackService.getStats(this.trackId);
    } catch (e) {
      console.log(e);
    }

    if (this.track === undefined) {
      return;
    }

    try {
      if (this.track.journal != undefined) {
        this.journal = await this.journalService.getJournal(this.track.journal?.id);
      } else {
        this.journals = await this.journalService.getJournals();
      }
    } catch (e) {
      //
    }

    if (this.journal !== undefined) {
      this.journalId = this.journal.id;

      if (this.journal?.journals === undefined) {
        this.journal!.journals = [];
      }

      this.journalEntity = this.journal?.journals?.find(e => new Date(e.date).getDate() === new Date(this.track?.createdAt ?? new Date()).getDate());

      if (this.journalEntity === undefined) {
        this.journalEntity = new JournalEntity();
        this.journalEntity!.date = this.track?.createdAt;
        this.journalEntity!.text = "-";
        this.journal?.journals?.push(this.journalEntity);

        try {
          await this.journalService.putJournal(this.journal!);
        } catch (e) {
          //
        }

      }
    }
  }

  drawCompleteTrack() {
    if (this.track?.records.length! > 0 && this.track?.records[0].data !== undefined && this.track?.records[0].data.length! > 0) {
      const firstRecord = this.track?.records[0]!;
      const lat = this.extractData.getLatitude(firstRecord.data);
      const lon = this.extractData.getLongitude(firstRecord.data);
      this.map = this.mapService.createMap(lon, lat, 'map');

      this.mapService.addStaticLayer(this.map, "openstreetmap");
      this.mapService.addStaticLayer(this.map, "openseamap");

      const resetControl = new Control({
        element: document.getElementById('reset-map')!
      });

      this.map.addControl(resetControl);

      let lastLat = -1;
      let lastLon = -1;

      this.coords = [];

      for (const record of this.track?.records!) {
        const lat = this.extractData.getLatitude(record.data);
        const lon = this.extractData.getLongitude(record.data);

        if (lat === undefined || lon === undefined || lat === 0 || lon === 0) {
          continue;
        }

        const coordinate: Coordinate = [lon, lat];
        this.coords.push(coordinate);

        const waypoint = this.mapService.drawTrackWaypoints([lastLon, lastLat], record);
        this.map.addLayer(waypoint.layer);

        const course = this.extractData.getCourse(record.data);

        if (course !== undefined) {
          const rotateIcon = this.mapService.createRotatedIcon([lastLon, lastLat], course);
          this.map.addLayer(rotateIcon);
        }

        if (lastLat !== -1 && lastLon !== -1) {
          const line = this.mapService.drawLine([lastLon, lastLat], [lon, lat]);
          this.map.addLayer(line!);
        }

        lastLat = lat;
        lastLon = lon;
      }

      this.mapService.centerMapOnCoordinates(this.map, this.coords);

      if (this.track?.records.length! > 0) {
        const startRecord = this.track?.records[0]!;
        const startLat = this.extractData.getLatitude(startRecord.data);
        const startLon = this.extractData.getLongitude(startRecord.data);
        const startCord: number[] = [startLon, startLat];
        const endCord: number[] = [lastLon, lastLat];
        this.startIcon = this.mapService.setFlag(startLat, startLon, this.mapService.startFlagIcon);
        this.endIcon = this.mapService.setFlag(lastLat, lastLon);
        this.map.addLayer(this.startIcon);
        this.map.addLayer(this.endIcon);
      }

      this.map.on('loadend', () => {
        //
      });

      this.map.on('click', async (e) => {
        const selectedCord = transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');

        let closestCord: Coordinate | null = null;
        let closestDistance: number | null = null;

        // Durchlaufen des Arrays, um die nächstgelegene Koordinate zu finden
        for (const cord of this.coords) {
          const distance = this.calculateDistance(selectedCord, cord);
          if (closestDistance === null || distance < closestDistance) {
            closestCord = cord;
            closestDistance = distance;
          }
        }

        if (closestCord) {
          await this.jumpToRecord(closestCord);
        } else {
          console.log('Keine Koordinaten vorhanden.');
        }
      });

    }
  }

  resetMap() {
    if (this.selectedRecord !== undefined) {
      this.mapService.centerMapOnCoordinates(this.map!, this.coords);
      this.selectedRecord = undefined;
    }
  }

  async jumpToRecord(cord: Coordinate, trackRecord: TrackRecord | null = null) {
    this.mapService.centerMapOnCoordinates(this.map!, [cord]);
    this.mapService.zoom(this.map!, 16);

    if (trackRecord !== null) {
      this.selectedRecord = trackRecord;
      return;
    }

    const record = this.track?.records.find(r => {
      const lat = this.extractData.getLatitude(r.data);
      const lon = this.extractData.getLongitude(r.data);
      return lat === cord![1] && lon === cord![0];
    });

    this.selectedRecord = await this.trackService.getRecord(record?.id!);
  }

  async selectRecordData(record: TrackRecord) {
    const cord = [this.extractData.getLongitude(record.data), this.extractData.getLatitude(record.data)];
    await this.jumpToRecord(cord, record);
  }

  calculateDistance(cord1: Coordinate, cord2: Coordinate): number {
    const [x1, y1] = cord1;
    const [x2, y2] = cord2;
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  getTime(record: TrackRecord) {
    moment.locale('de');
    return moment(record.createdAt).format('HH:mm');
  }

  getDate(date?: Date) {
    moment.locale('de');
    return moment(date).format('dddd, DD.MM.YYYY');
  }

  setJournal() {
    if (this.journalId == 0) {
      this.creationModal = true;
    } else if (this.journalId > 0) {
      //
    }
  }

  async saveJournal() {
    this.loader.visibility = true;
    let journal = this.journals.find(j => j.id === +this.journalId);
    if (journal !== undefined) {

      const trackCopy = Object.assign({}, this.track);
      trackCopy.records = [];

      if (journal.tracks === undefined) {
        journal.tracks = [];
      }
      if (journal.journals === undefined) {
        journal.journals = [];
      }

      journal.tracks.push(trackCopy);

      await this.journalService.putJournal(journal);
      await this.loadTrack();
    }
    this.loader.visibility = false;
  }

  async saveTrack() {
    try {
      this.loader.visibility = true;
      await this.trackService.putTrack(this.track!);
    } finally {
      this.loader.visibility = false;
    }
  }

  async updateJournal() {
    this.loader.visibility = true;
    if (this.journalEntity !== undefined && this.journalEntity.text === '') {
      this.journalEntity.text = '-';
    }
    await this.journalService.putJournal(this.journal!);
    this.loader.visibility = false;
  }

}
