import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output, ViewChild
} from '@angular/core';
import {GalleriaModule} from "primeng/galleria";
import {NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "primeng/api";
import {ContextMenu, ContextMenuModule} from "primeng/contextmenu";
import {AssetHelper, GalleryItem, JournalEntity, JournalEntityAsset} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-preview-entity-assets',
  standalone: true,
  imports: [
    GalleriaModule,
    NgForOf,
    NgIf,
    SharedModule,
    ContextMenuModule
  ],
  templateUrl: './journal-preview-entity-assets.component.html',
  styleUrl: './journal-preview-entity-assets.component.scss'
})
export class JournalPreviewEntityAssetsComponent implements AfterViewInit {
  @Input() entity?: JournalEntity;
  @Input() readonly = true;

  @Output() imageClickEvent = new EventEmitter<{ index: number, entity: JournalEntity }>();
  @Output() imageKeyDownEvent = new EventEmitter<KeyboardEvent>();
  @Output() loadMoreEvent = new EventEmitter<JournalEntity>();
  @Output() deleteAsset = new EventEmitter<JournalEntityAsset>();

  @ViewChild('cm') cm!: ContextMenu;
  selectedImage?: GalleryItem;

  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  imageContextMenuItems = [
    {label: 'in den Papierkorb', icon: 'pi pi-trash', command: (event: any) => this.deleteSelectedAsset()},
  ];

  constructor(private assetHelper: AssetHelper) {
    //
  }

  ngAfterViewInit() {
    this.loadAssets();
  }

  loadAssets() {
    for (const asset of this.entity!.assets) {
      if (asset.imageLoaded) {
        continue;
      }
      if (this.assetHelper.isImage(asset)) {
        asset.imageUrl = this.assetHelper.getAssetUrl(asset);
      } else if (asset.thumbnail != null) {
        asset.imageUrl = this.assetHelper.getAssetUrl(asset.thumbnail);
      }
      asset.imageLoaded = true;
    }
    this.entity!.galleryImages = this.getGalleryImages();
  }

  loadMore(entity: JournalEntity) {
    this.entity!.displayMore = true;
    this.entity!.galleryImages = this.getGalleryImages();
  }

  getGalleryImages() {
    const images: GalleryItem[] = [];
    let i = 0;
    this.entity!.assets = this.sortedAssets(this.entity!);
    for (const asset of this.entity!.assets) {
      if (!this.entity!.displayMore && i >= 4) {
        break;
      }
      if (this.assetHelper.isImage(asset)) {
        images.push(new GalleryItem(this.entity!, asset, asset.imageUrl, asset.imageUrl, '', ''));
      } else if (this.assetHelper.isVideo(asset)) {
        images.push(new GalleryItem(this.entity!, asset, asset.videoUrl, asset.thumbnail?.imageUrl ?? asset.imageUrl, '', ''));
      }
      i++;
    }
    return images;
  }

  sortedAssets(entity: JournalEntity) {
    const orderByDate = entity.assets.sort((a, b) => new Date(a.date ?? new Date).getTime() - new Date(b.date ?? new Date()).getTime());
    const images = orderByDate.filter(asset => this.assetHelper.isImage(asset));
    const videos = orderByDate.filter(asset => this.assetHelper.isVideo(asset));
    return images.concat(videos);
  }

  imageKeyDown(e: KeyboardEvent) {
    e.preventDefault();
    if (this.entity === undefined) {
      return;
    }
    switch (e.key) {
      case 'ArrowLeft':
        if (this.entity.selectedAssetIndex >= 0) {
          this.entity.selectedAssetIndex--;
        } else if (this.entity.selectedAssetIndex === 0) {
          this.entity.selectedAssetIndex = this.entity.assets.length - 1;
        }
        break;
      case 'ArrowRight':
        if (this.entity.selectedAssetIndex < this.entity.assets.length - 1) {
          this.entity.selectedAssetIndex++;
        } else if (this.entity.selectedAssetIndex === this.entity.assets.length - 1) {
          this.entity.selectedAssetIndex = 0;
        }
        break;
      case 'Escape':
        this.entity.fullScreen = false;
        break;
    }
  }

  imageClick(index: number, entity: JournalEntity) {
    entity.displayMore = true;
    entity.galleryImages = this.getGalleryImages();
    for (const image of entity.galleryImages) {
      image.itemImageSrc = this.assetHelper.getAssetUrl(image.asset, "md");
    }
    entity.selectedAssetIndex = index;
    entity.fullScreen = true;
    this.imageClickEvent.emit({index, entity});
  }

  imageLoaded(item: GalleryItem) {
    item.itemImageSrc = this.assetHelper.getAssetUrl(item.asset, "md");
  }

  isVideo(asset: JournalEntityAsset) {
    return this.assetHelper.isVideo(asset);
  }

  isImage(asset: JournalEntityAsset) {
    return this.assetHelper.isImage(asset);
  }

  getVideoMimeType(asset: JournalEntityAsset) {
    if (asset === undefined) {
      return;
    }
    switch (asset.filename.split('.').pop()) {
      case 'mp4':
        return 'video/mp4';
      case 'mpeg':
        return 'video/mpeg';
      default: // mov
        return 'video/mp4';
    }
  }

  onContextMenu(image: GalleryItem, event: any) {
    if (!this.readonly) {
      this.selectedImage = image;
      this.cm.target = event.currentTarget;
      this.cm.show(event);
    }
  }

  onHide() {
    this.selectedImage = undefined;
  }

  deleteSelectedAsset() {
    this.deleteAsset.emit(this.selectedImage!.asset);
    this.selectedImage = undefined;
  }

}
