import {AfterViewInit, Component} from '@angular/core';
import {VerifySessionService} from "../../verfiy-session/verify-session.service";
import {LoaderService} from "../../loader/loader.service";
import {NgForOf, NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {KeyFilterModule} from "primeng/keyfilter";
import {ToggleButtonModule} from "primeng/togglebutton";
import {MessageModule} from "primeng/message";
import {ButtonModule} from "primeng/button";
import {AuthService, SettingsService, PermissionService, PermissionType} from "logbuch-client-sdk";

@Component({
  selector: 'app-settings-system-tab',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    InputTextModule,
    FormsModule,
    InputNumberModule,
    KeyFilterModule,
    ToggleButtonModule,
    MessageModule,
    ButtonModule
  ],
  templateUrl: './settings-system-tab.component.html',
  styleUrl: './settings-system-tab.component.scss'
})
export class SettingsSystemTabComponent implements AfterViewInit {

  isVerified = false;
  isAdmin = false;

  setupItems: {
    name: string,
    value: string | number | boolean
  }[] = [];

  constructor(private verifySession: VerifySessionService,
              private authService: AuthService,
              private permissionService: PermissionService,
              private settingsService: SettingsService,
              private loader: LoaderService) {
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      this.loader.visibility = true;
      const session = await this.authService.checkSession();
      await this.checkPermission();
      this.loader.visibility = false;
      if (!session.isVerified) {
        await this.verifySession.verifySession();
      }
      this.isVerified = true;
      if (this.isAdmin) {
        await this.readSettings();
      }
    } finally {
      //
    }
  }

  async verifySessionOnClick() {
    try {
      this.loader.visibility = true;
      await this.verifySession.verifySession();
      this.isVerified = true;
    } finally {
      this.loader.visibility = false;
    }
  }

  async checkPermission() {
    try {
      this.isAdmin = await this.permissionService.checkReadable(PermissionType.Administrator);
    } finally {
      //
    }
  }

  async readSettings() {
    try {
      this.loader.visibility = true;
      this.setupItems = await this.settingsService.getSetupItems();
    } finally {
      this.loader.visibility = false;
    }
  }

  getType(item: {
    name: string,
    value: string | number | boolean
  }): string {
    if (typeof item.value === 'boolean' || item.value === 'true' || item.value === 'false') {
      return 'boolean';
    } else if (typeof item.value === 'string') {
      return 'string';
    } else { // if (typeof item.value === 'number')
      return 'number';
    }
  }

  async saveItem(item: {
    name: string,
    value: string | number | boolean
  }) {
    try {
      this.loader.visibility = true;
      if (typeof item.value === 'boolean' || item.value === 'true' || item.value === 'false') {
        await this.settingsService.saveSetupItem(item.name, item.value == true || item.value === 'true' ? 'true' : 'false', 'boolean');
      } else if (typeof item.value === 'string') {
        await this.settingsService.saveSetupItem(item.name, item.value.toString(), 'string');
      } else {
        await this.settingsService.saveSetupItem(item.name, item.value.toString(), 'number');
      }
    } finally {
      this.loader.visibility = false;
    }
  }

}
