<div class="wrapper">
  <div class="container">
    <div class="left">

      <div class="header">
        <img src="assets/logo2.png">
        <div>
          <label>DILOG</label>
          <small>Das digitale Logbuch</small>
        </div>
      </div>

      <div class="form-group">
        <label for="email">E-Mail Adresse</label>
        <input
          [(ngModel)]="email"
          (keydown)="keyDown($event)"
          pInputText
          id="email"
          type="email"
          aria-describedby="email-help" />
        <small *ngIf="!isValidMail" id="email-help">
          Bitte gebe deine E-Mail Adresse ein.
        </small>
      </div>

      <div *ngIf="usePassword" class="form-group">
        <label for="password">Dein Passwort</label>
        <input
          [(ngModel)]="password"
          (keydown)="keyDown($event)"
          pInputText
          id="password"
          type="password"
          aria-describedby="password-help" />
      </div>

      <p-messages
        [(value)]="messages"
        [enableService]="false"
        [closable]="true" />

      <div class="row sm-col" style="justify-content: flex-end; gap: 1rem;">
        <p-button *ngIf="isMethodAllowed(LoginMethod.Password) && !usePassword"
                  (onClick)="usePassword = !usePassword" label="Passwort eingeben"
                  icon="pi pi-key" severity="secondary" iconPos="right" />
        <p-button *ngIf="isMethodAllowed(LoginMethod.Mail) && showMailButton"
                  (onClick)="loginButtonOnClick(true)" label="per E-Mail Anmelden"
                  icon="pi pi-at" severity="secondary" iconPos="right" />
        <p-button (onClick)="loginButtonOnClick()" label="Anmelden" icon="pi pi-sign-in" iconPos="right" />
      </div>

    </div>
    <div class="right">
      <img src="assets/logbook.png">
    </div>

    <div *ngIf="isBusy" class="container-busy-overlay">
      <p-progressSpinner
        styleClass="w-3rem h-3rem"
        strokeWidth="2"
        fill="transparent"
        animationDuration="1.25s" />
    </div>
  </div>

</div>

<p-dialog
  header="Datenschutz & Nutzungsbedingungen"
  [modal]="true"
  [(visible)]="legalModalVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <section>
    <label>Anmeldung und Datenschutz bei DiLog</label>
    <span>Um DiLog, unser digitales Logbuch, nutzen zu können, benötigen wir einige persönliche Daten von Ihnen. Der Schutz Ihrer Daten ist uns wichtig. Bitte lesen Sie die folgenden Informationen sorgfältig durch.</span>
  </section>

  <section>
    <label>Datenerhebung und -Verarbeitung</label>

    <label>Pflichtangaben:</label>
    <ul>
      <li>E-Mail-Adresse (für Kontozugang und Benachrichtigungen)</li>
    </ul>

    <label>Optionale Angaben:</label>
    <ul>
      <li>Vor- und Nachname</li>
      <li>Telefonnummer</li>
    </ul>
  </section>

  <section>
    <label>Ihre Rechte und Einwilligungen</label>
    <ul>
      <li>Die Verarbeitung Ihrer Daten erfolgt gemäß Art. 6 Abs. 1 lit. a DSGVO ausschließlich mit Ihrer Einwilligung</li>
      <li>Sie haben das Recht auf:
        <ul>
          <li>Auskunft über gespeicherte Daten</li>
          <li>Berichtigung falscher Daten</li>
          <li>Löschung Ihrer Daten ("Recht auf Vergessen")</li>
          <li>Export Ihrer DiLog-Daten</li>
        </ul>
      </li>
      <li>Ihre hochgeladenen Medien können jederzeit von Ihnen gelöscht oder geändert werden</li>
      <li>Der Widerruf Ihrer Einwilligung ist jederzeit möglich über: privacy&#64;dilog.page</li>
    </ul>
  </section>

  <section>
    <label>Erforderliche Einwilligung</label>

    <div class="row" style="gap: 0.5rem; align-items: center;">
      <div style="width: auto">
        <p-checkbox
          [value]="acceptTerms"
          (onChange)="acceptTerms = $event.checked; toggleTermsAndConditions()"
          id="privacy" />
      </div>
      <label for="privacy">
        Ich habe die <a href="https://dilog.page/datenschutz" target="_blank">Datenschutzerklärung</a> gelesen und stimme dieser zu.
      </label>
    </div>
  </section>

  <footer>
    <label>Wichtige Hinweise:</label>
    <ul>
      <li>Ihre Einwilligung ist freiwillig und kann jederzeit widerrufen werden</li>
      <li>Bei Löschung Ihres Kontos werden alle zugehörigen Daten, Bilder und Videos unwiderruflich entfernt</li>
      <li>Die Nutzung von DiLog ist erst nach erfolgter Einwilligung möglich</li>
      <li>Fragen zum Datenschutz beantwortet unser Datenschutzbeauftragter: privacy&#64;dilog.page</li>
    </ul>
  </footer>
</p-dialog>
