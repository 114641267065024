import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {InputTextModule} from "primeng/inputtext";
import {FloatLabelModule} from "primeng/floatlabel";
import {ButtonGroupModule} from "primeng/buttongroup";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CrewCreatorDialogComponent} from "../../crew-creator-dialog/crew-creator-dialog.component";
import {LoaderService} from "../../loader/loader.service";
import {
  Crew,
  CrewMember,
  CrewService,
  Journal,
  JournalEntity,
  RegistryService,
  RestCountry,
  StringHelper
} from "logbuch-client-sdk";
import {AlertModalService} from "../../alert-modal/alert-modal.service";

@Component({
  selector: 'app-journal-creation',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    CalendarModule,
    InputTextModule,
    FloatLabelModule,
    ButtonGroupModule,
    AutoCompleteModule,
    CrewCreatorDialogComponent
  ],
  templateUrl: './creation.component.html',
  styleUrl: './creation.component.scss'
})
export class CreationComponent implements OnInit {

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() created: EventEmitter<number> = new EventEmitter<number>();

  obj: Journal = new Journal();
  countries: RestCountry[] = [];
  countryNames: string[] = [];

  showCrewEditor: boolean = false;
  crewMembers: CrewMember[] = [];
  crew?: Crew;

  startAt?: Date;
  endAt?: Date;

  constructor(private services: RegistryService,
              private crewService: CrewService,
              private alert: AlertModalService,
              private loader: LoaderService) {
    //
  }

  countryChanged(event: any) {
    try {
      this.obj.country = event.translations.deu.common;
    } catch (e) {
      //
    }
  }

  async ngOnInit() {
    try {
      this.loader.visibility = true;
      this.crewMembers = await this.crewService.getCrewMembers();
    } finally {
      this.loader.visibility = false;
    }
  }

  async loadCountries(event: any) {
    if (event.query == null || event.query.length < 2) {
      return;
    }
    this.countryNames = [];
    this.countries = await this.services.external.getCountries(event.query);
    this.countries.forEach((country: any) => {
      this.countryNames.push(country.translations.deu.common);
    });
  }

  async create() {
    try {

      if (this.startAt != null && this.endAt != null && this.startAt > this.endAt) {
        this.alert.show("Das Startdatum darf nicht nach dem Enddatum liegen.");
        return;
      } else if (this.startAt == null && this.endAt != null) {
        this.alert.show("Das Startdatum darf nicht leer sein.");
        return;
      } else if (this.startAt != null && this.endAt == null) {
        this.alert.show("Das Enddatum darf nicht leer sein.");
        return;
      } else if (this.startAt != null && this.endAt != null) {
        this.obj.year = +(new Date(this.startAt).getFullYear());
      }

      if (this.obj.year == null) {
        this.alert.show("Zur Erstellung eines Törns muss ein Jahr angegeben werden.");
        return;
      }

      if (StringHelper.isNullOrEmpty(this.obj.title)) {
        this.alert.show("Bitte gebe einen Namen / Titel für den Törn an.");
        return;
      }

      this.loader.visibility = true;
      this.obj.year = +(this.obj.year ?? new Date().getFullYear());

      const id = await this.services.journal.postJournal(this.obj);
      if (id > 0) {

        if (this.startAt != null && this.endAt != null) {
          const journal = await this.services.journal.getJournal(id);
          if (journal != null) {

            // Get Dates between startAt and endAt
            const dates = this.getDaysInRange(new Date(this.startAt), new Date(this.endAt));

            for (const date of dates) {
              const entity = new JournalEntity();
              entity.text = "Erzähle an dieser Stelle etwas von dem Tag auf See...";
              entity.date = date;
              entity.dateOnly = date;
              entity.assets = [];
              if (journal.journals == null) {
                journal.journals = [];
              }
              journal.journals.push(entity);
            }

            await this.services.journal.putJournal(journal);

          }
        }

        this.close();
        this.created.emit(id);
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  close() {
    this.obj = new Journal();
    this.closed.emit();
  }

  getDaysInRange(startDate: Date, endDate: Date): Date[] {
    const daysArray: Date[] = [];

    // Startdatum auf UTC setzen und Uhrzeit auf Mitternacht (00:00:00.000)
    const start = new Date(Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    ));

    // Enddatum auf UTC setzen und Uhrzeit auf Mitternacht (00:00:00.000)
    const end = new Date(Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    ));

    // Kopie des Startdatums für die Iteration
    let currentDate = new Date(start);

    while (currentDate <= end) {
      // Neues Date-Objekt mit UTC Zeit erstellen
      daysArray.push(new Date(Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate()
      )));

      // Zum nächsten Tag
      currentDate = new Date(Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate() + 1
      ));
    }

    return daysArray;
  }

  showCrewEditorDialog() {
    this.showCrewEditor = true;
    this.crew = new Crew();
  }

  deleteCrew() {
    this.crew = undefined;
    this.obj.crew = undefined;
  }

  crewUpdated(crew: Crew) {
    this.crew = crew;
    this.obj.crew = this.crew;
  }

}
