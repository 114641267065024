import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {QRCodeModule} from "angularx-qrcode";
import {JournalEntity, RegistryService} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-edit-modal',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    NgForOf,
    QRCodeModule
  ],
  templateUrl: './edit-modal.component.html',
  styleUrl: './edit-modal.component.scss'
})
export class EditModalComponent {

  @Input()
  journalId?: number;

  @Input()
  obj?: JournalEntity;

  selectedFiles?: FileList;
  files: FileObject[] = [];
  loader = false;

  @Output()
  updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private services: RegistryService,
              private alertService: AlertModalService) {
    //
  }

  async upload() {

    let filesUploaded = false;

    this.loader = true;

    if (this.obj?.id != null) {

      const allowedFileType = ['image/jpeg', 'image/png', 'video/mp4', 'video/mpeg', 'video/quicktime'];

      if (this.files.find(f => !allowedFileType.includes(f.file.type.toLowerCase())) != null) {
        this.alertService.show('Es werden nur JPEG, PNG, MP4, MOV und MPEG Dateien unterstützt!');
        this.loader = false;
        return;
      }

      for (const file of this.files) {
        filesUploaded = true;
        const sub = this.services.journal.progress.subscribe(progress => {
          file.progress = progress;
        });
        try {
          await this.services.journal.postAsset(this.obj!.id, file.file);
          sub.unsubscribe();
          file.complete = true;
        } catch (e) {
          this.loader = false;
          return;
        }
      }
    }

    this.loader = false;

    this.updated.emit(filesUploaded);
  }

  close() {
    this.updated.emit(false);
  }

  filesSelected(event: any) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles != undefined) {
      this.files = [];
      for (let i = 0; i < this.selectedFiles?.length; i++) {
        const file = this.selectedFiles.item(i);
        if (file != null) {
          this.files.push(new FileObject(file));
        }
      }
    }
  }

}

export class FileObject {
  public progress = 0;
  public complete = false;

  constructor(public file: File) {
    //
  }
}
