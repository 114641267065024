import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {GatewayManagementComponent} from "../gateway-management/gateway-management.component";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import moment from "moment";
import {SecurityQueryService} from "../../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../../securityQuery/securityQuery.component";
import {AppService} from "../../app.service";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CalendarModule} from "primeng/calendar";
import {DialogModule} from "primeng/dialog";
import {ProgressBarModule} from "primeng/progressbar";
import {ActiveMembership, Membership, PermissionType, RegistryService, UserDetails, NumericHelper} from "logbuch-client-sdk";

@Component({
  selector: 'app-membership-management',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    NgClass,
    GatewayManagementComponent,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    DialogModule,
    ProgressBarModule
  ],
  templateUrl: './membership-management.component.html',
  styleUrl: './membership-management.component.scss'
})
export class MembershipManagementComponent implements OnInit {

  memberships?: ActiveMembership[];
  availableMemberships?: Membership[];
  users?: UserDetails[];

  selectedMembership?: ActiveMembership;

  selectedUser?: UserDetails;
  selectedMembershipType?: Membership;
  expiredAt? = null;

  memberShipEdit = false;

  get validMemberships() {
    if (this.memberships === undefined) {
      return [];
    }
    return this.memberships.filter(x => x.expiredAt == null || new Date(x.expiredAt) > new Date());
  }

  get isLoading() {
    return this.memberships === undefined || this.availableMemberships === undefined || this.users === undefined;
  }

  constructor(private services: RegistryService,
              private router: Router,
              private alertService: AlertModalService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService,
              private numericHelper: NumericHelper,
              private appService: AppService) {
    //
  }

  async ngOnInit() {
    if (!await this.services.permission.checkReadable(PermissionType.Administrator)) {
      await this.router.navigate(['/login']);
    }

    this.availableMemberships = await this.services.membership.getMemberships();
    await this.loadMemberships();
  }

  openContextMenu(membership: ActiveMembership) {
    this.selectedMembership = membership;
    membership.modalVisible = true;
  }

  async loadMemberships() {
    this.selectedUser = undefined;
    this.selectedMembershipType = undefined;
    this.expiredAt = null;
    this.memberships = await this.services.membership.getActiveMemberships();
    this.users = await this.services.admin.getUsers();
    this.appService.currentUserChanged.next();
  }

  async addMembership() {
    if (this.users === undefined || this.availableMemberships === undefined) {
      return;
    }
    this.loader.visibility = true;
    const selectedUser = this.users.find(x => x.id === this.selectedUser?.id);
    const selectedMembership = this.availableMemberships.find(x => x.id === this.selectedMembershipType?.id);
    if (selectedUser !== undefined && selectedMembership !== undefined) {
      try {
        await this.services.membership.addMembership(selectedUser, selectedMembership, this.expiredAt);
        this.alertService.show('Die Mitgliedschaft wurde erfolgreich dem ausgewählten Account hinzugefügt!', 6000, AlertType.Success);
        await this.loadMemberships();
      } catch (e) {
        //
      }
    } else {
      this.alertService.show('Es wurde kein Benutzer oder Mitgliedschaft ausgewählt!', 6000, AlertType.Error);
    }
    this.loader.visibility = false;
  }

  async revokeSelectedMembership() {
    this.selectedMembership!.modalVisible = false;
    if (this.selectedMembership !== undefined) {
      if (await this.securityQuery.show('Möchten Sie die Mitgliedschaft wirklich widerrufen?', true, true, false) == SecurityQueryResult.Yes) {
        this.loader.visibility = true;
        try {
          await this.services.membership.revokeMembership(this.selectedMembership.user);
          this.alertService.show('Die Mitgliedschaft wurde erfolgreich widerrufen!', 6000, AlertType.Success);
          await this.loadMemberships();
        } catch (e) {
          //
        }
      }
    } else {
      this.alertService.show('Es wurde keine Mitgliedschaft ausgewählt!', 6000, AlertType.Error);
    }
    this.loader.visibility = false;
  }

  getMailOfUser(userId: number) {
    if (this.users === undefined) {
      return '';
    }
    const user = this.users.find(x => x.id === userId);
    if (user !== undefined) {
      return user.user.email;
    }
    return '';
  }

  isEven(i: number) {
    return i % 2 === 0;
  }

  getTime(date: Date) {
    moment.locale('de');
    return moment(new Date(date)).format('LL');
  }

  isSize(column: string) {
    return column.includes('size');
  }

  getSize(bytes: number) {
    return this.numericHelper.formatBytes(bytes);
  }

  async memberShipKeyDown(event: KeyboardEvent) {
    if (this.availableMemberships === undefined) {
      return;
    }
    if (event.key === 'Enter') {
      try {
        await this.services.membership.updateMembershipProperties(this.availableMemberships);
        this.memberShipEdit = false;
      } catch (e) {
        //
      }
    }
  }

  protected readonly close = close;
}
