<div class="core-wrapper">
  <div class="core-container">
    <div class="title">
      <div class="header">
        <div class="main">
          <div *ngIf="appService.isAuth" class="search">
            <p-menu #menu [model]="menuItems" [popup]="true"/>
            <p-button (onClick)="menu.toggle($event)" icon="pi pi-bars" severity="secondary" [rounded]="true"/>
            <i class="pi pi-search"></i>
            <input [(ngModel)]="searchValue"
                   (ngModelChange)="search()"
                   type="text" placeholder="Logbuch durchsuchen">
          </div>
          <div routerLink="/journal" class="main-title">
            <label>Logbuch</label>
            <span *ngIf="appService.isAuth">von {{ DisplayName }}</span>
            <span *ngIf="!appService.isAuth">Willkommen, probiere es gleich aus!</span>
          </div>
          <div *ngIf="appService.isAuth" class="profile">
            <!--<i class="pi pi-user"></i>-->
            <div *ngIf="stats !== undefined">
              <div class="name">Auf einen Blick!</div>
              <div class="details">
                <div pTooltip="Anzahl an Törns" tooltipPosition="bottom">
                  <i class="pi pi-book"></i>
                  <label>{{ stats.journals }} Törn<span *ngIf="stats.journals > 1">s</span></label>
                </div>
                <div pTooltip="zurückgelegte Strecke in Meilen" tooltipPosition="bottom">
                  <i class="pi pi-map"></i>
                  <label>{{ stats.miles }} NM</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="splitter"></span>
        <!-- <app-nav/> -->
      </div>
    </div>
    <div class="content">
      <router-outlet />
    </div>
  </div>
  <app-footer/>
</div>

<app-crew-editor/>
<app-sort/>
<app-alert-modal/>
<app-video-player/>
<app-security-query/>
<app-issue/>
<app-verify-session/>
<app-loader/>
