"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginMethod = void 0;
var LoginMethod;
(function (LoginMethod) {
  LoginMethod[LoginMethod["Password"] = 0] = "Password";
  LoginMethod[LoginMethod["Passkey"] = 1] = "Passkey";
  LoginMethod[LoginMethod["Mail"] = 2] = "Mail";
})(LoginMethod || (exports.LoginMethod = LoginMethod = {}));