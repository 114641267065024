import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {GatewayManagementService} from "./gateway-management.service";
import {ButtonModule} from "primeng/button";
import {Gateway, UserDetails} from "logbuch-client-sdk";

@Component({
  selector: 'app-gateway-management',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    NgClass,
    ButtonModule
  ],
  templateUrl: './gateway-management.component.html',
  styleUrl: './gateway-management.component.scss'
})
export class GatewayManagementComponent {

  editMode = false;

  constructor(public service: GatewayManagementService) {
    //
  }

  async setOwner(gateway: Gateway, event: boolean) {
    const oldOwner = Object.assign(new UserDetails(), gateway.owner);

    if (this.service.user == null) {
      return;
    }

    gateway.owner = event ? this.service.user : null;

    this.service.loader.visibility = true;

    try {
      await this.service.services.gateway.saveGateway(gateway);
      this.service.propertyChanged.next();
    } catch (e) {
      gateway.owner = oldOwner;
    }

    this.service.setOwnerFlag(gateway);

    this.service.loader.visibility = false;
  }

  async setMember(gateway: Gateway, event: boolean) {

    const oldMembers = gateway.members?.map(x => Object.assign(new UserDetails(), x));

    if (this.service.user == null) {
      return;
    }

    if (event) {
      if (gateway.members == null) {
        gateway.members = [];
      }
      gateway.members.push(this.service.user);
    } else {
      gateway.members?.splice(gateway.members?.findIndex(m => m.id === this.service.user?.id), 1);
    }

    this.service.loader.visibility = true;

    try {
      await this.service.services.gateway.saveGateway(gateway);
      this.service.propertyChanged.next();
    } catch (e) {
      gateway.members?.splice(gateway.members?.findIndex(m => m.id === this.service.user?.id), 1);
    }

    this.service.setMemberFlag(gateway);

    this.service.loader.visibility = false;
  }

  async leaveEditMode(event: KeyboardEvent, gateway: Gateway) {
    if (event.key === "Enter") {
      try {
        await this.service.services.gateway.saveGateway(gateway);
        this.editMode = false;
      } catch (e) {
        //
      }
    }
  }

}
