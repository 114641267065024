import { Injectable } from "@angular/core";
import { OpenlayersExtension } from "../../extensions/openlayers.extension";
import { Map } from "ol";
import { LogPrefix } from "../app.config";
import {CryptoService, JournalEntityAsset, MapType, RegistryService, StringHelper} from "logbuch-client-sdk";
import {MapKitAnnotationOptions, MapKitCredentials} from "logbuch-client-sdk/lib/types/mapkit.types";

declare global {
  interface Window {
    mapkit: any;
  }
}

const MAPKIT_LIBRARIES = ["map", "full-map", "annotations"] as const;
const DEFAULT_SPAN = 0.05;
const DEFAULT_MARKER_COLOR = "#ec3b3b";
const MAPKIT_INIT_DELAY = 200;

@Injectable()
export class MapService extends OpenlayersExtension {
  private map?: Map;
  private mapKitMap?: any;
  private items: any[] = [];
  apiToken?: string;
  mapType?: MapType;

  public mapLoaded = false;
  public mapkitLibLoaded = false;

  constructor(
    private services: RegistryService,
    private cryptoService: CryptoService
  ) {
    super();
  }

  /**
   * Lädt den API-Key für MapKit
   */
  async loadKey(): Promise<void> {
    try {
      const result: MapKitCredentials = await this.services.mapKit.getDetails();
      this.apiToken = result.ec
        ? this.cryptoService.decryptSingleValue(result.token)
        : result.token;
      this.mapType = result.type;
    } catch (error) {
      console.error(
        `${LogPrefix.E} API-Credentials of Maps could not be loaded.`,
        error
      );
      throw error;
    }
  }

  /**
   * Lädt die MapKit Bibliothek vor
   */
  async preloadMapKit(): Promise<void> {
    console.log(`${LogPrefix.I} Preloading MapKit...`);
    try {
      if (this.mapkitLibLoaded) {
        return;
      }

      window.mapkit.load(MAPKIT_LIBRARIES);

      if (StringHelper.isNullOrEmpty(this.apiToken)) {
        await this.loadKey();
      }

      await window.mapkit.init({
        authorizationCallback: async (done: (token: string) => void) => {
          done(this.apiToken!);
        },
        language: "de"
      });

      await new Promise(resolve => setTimeout(resolve, MAPKIT_INIT_DELAY));
      this.mapkitLibLoaded = true;
    } catch (error) {
      console.error(`${LogPrefix.E} Could not load MapKit`, error);
      throw error;
    }
  }

  /**
   * Initialisiert die Karte mit einer Position
   */
  async init(lat: number, lon: number): Promise<void> {
    try {
      if (this.map) {
        this.deleteMap(this.map);
      }

      if (!this.mapkitLibLoaded) {
        await this.preloadMapKit();
      }

      console.log(lat, lon);

      this.mapKitMap = new window.mapkit.Map("map-container");
      await this.addMarker(lat, lon);
      await this.setCenterAndZoom(lat, lon, 1);

      this.mapLoaded = true;
    } catch (error) {
      console.error(`${LogPrefix} MapService.init failed`, error);
      throw error;
    }
  }

  /**
   * Fügt einen Marker zur Karte hinzu
   */
  async addMarker(
    lat: number,
    lon: number,
    options: MapKitAnnotationOptions = {}
  ): Promise<void> {
    const MarkerAnnotation = window.mapkit.MarkerAnnotation;
    const marker = new MarkerAnnotation(
      new window.mapkit.Coordinate(lat, lon),
      {
        color: options.color || DEFAULT_MARKER_COLOR,
        ...options
      }
    );

    this.items.push(marker);
    this.mapKitMap?.showItems(this.items);
  }

  /**
   * Setzt das Zentrum und den Zoom der Karte
   */
  async setCenterAndZoom(
    lat: number,
    lon: number,
    span: number = DEFAULT_SPAN
  ): Promise<void> {
    const coordinate = new window.mapkit.Coordinate(lat, lon);
    const coordinateSpan = new window.mapkit.CoordinateSpan(span);
    const region = new window.mapkit.CoordinateRegion(coordinate, coordinateSpan);

    this.mapKitMap?.setRegionAnimated(region);
  }

  /**
   * Aktualisiert die Kartenansicht bei Änderung der Auswahl
   */
  async selectionChanged(
    asset: JournalEntityAsset,
    retry: boolean = true
  ): Promise<void> {
    if (!retry || !this.mapKitMap) {
      return;
    }

    try {
      // Entferne alte Marker
      this.mapKitMap.removeItems(this.items);
      this.items = [];

      // Füge neuen Marker hinzu
      await this.addMarker(asset.latitude, asset.longitude);

      // Zentriere Karte auf neue Position
      await this.setCenterAndZoom(asset.latitude, asset.longitude);
    } catch (error) {
      console.error(
        `${LogPrefix.E} Error updating map selection`,
        error
      );
      throw error;
    }
  }

  /**
   * Säubert die Karteninstanz
   */
  public cleanup(): void {
    if (this.mapKitMap) {
      this.mapKitMap.removeItems(this.items);
      this.items = [];
      this.mapLoaded = false;
    }
  }
}
