import {Injectable} from '@angular/core';
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {LoaderService} from "../loader/loader.service";
import {MapService} from "../map/map.service";
import {Subject} from "rxjs";
import {JournalEntity, JournalEntityAsset, RegistryService, BaseService} from "logbuch-client-sdk";

@Injectable()
export class PicturePreviewService {

  closeComponent = new Subject<void>();

  readonly = false;

  assets: JournalEntityAsset[] = [];
  selectedAsset?: JournalEntityAsset = undefined;

  constructor(private securityQuery: SecurityQueryService,
              private loader: LoaderService,
              private services: RegistryService,
              private base: BaseService,
              private mapService: MapService) {
    //
  }

  async deleteSelectedAsset() {
    if (await this.securityQuery.show('Möchtest du das ausgewählte Bild wirklich löschen?', true, true, false) == SecurityQueryResult.Yes) {
      this.loader.visibility = true;
      try {
        await this.services.journal.deleteAsset(this.selectedAsset!.id);
        if (this.assets.length <= 1) {
          this.closeComponent.next();
          return;
        }
        this.assets.splice(this.assets.indexOf(this.selectedAsset!), 1);
        this.selectedAsset = this.assets.length > 0 ? this.assets[0] : undefined;
      } catch (e) {
        //
      }
      this.loader.visibility = false;
    }
  }

  getVideoUrl() {
    return `${this.base.url}/journal/asset/${this.selectedAsset!.id}?auth=${this.base.httpOptions.headers.Authorization}`;
  }

  getVideoMimeType(asset: JournalEntityAsset | null = null) {
    switch ((asset == null ? this.selectedAsset! : asset).filename.split('.').pop()) {
      case 'mp4':
        return 'video/mp4';
      case 'mpeg':
        return 'video/mpeg';
      default: // mov
        return 'video/quicktime';
    }
  }

  isImage(asset: JournalEntityAsset) {
    return asset.filename.toLowerCase().endsWith('.jpg')
      || asset.filename.toLowerCase().endsWith('.png')
      || asset.filename.toLowerCase().endsWith('.jpeg');
  }

  isVideo(asset: JournalEntityAsset) {
    return asset.filename.toLowerCase().endsWith('.mp4')
      || asset.filename.toLowerCase().endsWith('.mpeg')
      || asset.filename.toLowerCase().endsWith('.mov');
  }

  gpsDataAvailable(asset: JournalEntityAsset = this.selectedAsset!) {
    if (asset == undefined) {
      return false;
    } else {
      return !(asset.latitude == -1 && asset.longitude == -1);
    }
  }

  selectNext() {
    let index = this.assets.indexOf(this.selectedAsset!);
    if (index < this.assets.length - 1) {
      this.selectedAsset = this.assets[index + 1];
      this.loadNextAndPreviousAssets();
      this.mapService.selectionChanged(this.selectedAsset);
    }
  }

  isNextAvailable() {
    return this.assets.indexOf(this.selectedAsset!) < this.assets.length - 1;
  }

  selectPrevious() {
    let index = this.assets.indexOf(this.selectedAsset!);
    if (index > 0) {
      this.selectedAsset = this.assets[index - 1];
      this.loadNextAndPreviousAssets();
      this.mapService.selectionChanged(this.selectedAsset);
    }
  }

  isPreviousAvailable() {
    return this.assets.indexOf(this.selectedAsset!) > 0;
  }

  async setAssetUrls(entity: JournalEntity, isPreview: boolean = false) {
    if (entity.assets != null) {
      const assets = entity.assets.filter(a => !a.imageLoaded);
      let i = 0;
      for (const asset of assets) {
        this.loadImage(asset).then();
        if (isPreview && i++ >= 4) {
          break;
        }
      }
    } else {
      entity.assets = [];
    }
  }

  async loadImage(asset: JournalEntityAsset, quality: "sm" | "md" | "lg" = "sm") {
    if (asset.filename.toLowerCase().endsWith('.png')
      || asset.filename.toLowerCase().endsWith('.jpeg')
      || asset.filename.toLowerCase().endsWith('.jpg')) {
      if (quality === "sm") {
        if (asset.imageLoaded) {
          return;
        }
        asset.imageLoaded = true;
      } else if (quality === "md" || quality === "lg") {
        if (asset.largeImageLoaded) {
          return;
        }
        asset.largeImageLoaded = true;
      }
      asset.imageLoaded = true;
      const blob = await this.services.journal.getAsset(asset.id, quality);
      if (blob !== undefined) {
        const urlCreator = window.URL || window.webkitURL;
        asset.imageUrl = urlCreator.createObjectURL(blob);
      }
    }
  }

  loadNextAndPreviousAssets() {
    const preloadAssets: number[] = [];
    const indexCurrent = this.assets.indexOf(this.selectedAsset!);

    if (this.checkIfIndexIsInArray(indexCurrent + 1)) {
      preloadAssets.push(indexCurrent + 1);
      if (this.checkIfIndexIsInArray(indexCurrent + 2)) {
        preloadAssets.push(indexCurrent + 2);
        if (this.checkIfIndexIsInArray(indexCurrent + 3)) {
          preloadAssets.push(indexCurrent + 3);
        }
      }
    }

    if (this.checkIfIndexIsInArray(indexCurrent - 1)) {
      preloadAssets.push(indexCurrent - 1);
      if (this.checkIfIndexIsInArray(indexCurrent - 2)) {
        preloadAssets.push(indexCurrent - 2);
        if (this.checkIfIndexIsInArray(indexCurrent - 3)) {
          preloadAssets.push(indexCurrent - 3);
        }
      }
    }

    for (const index of preloadAssets) {
      this.loadImage(this.assets[index], "md").then();
    }

  }

  checkIfIndexIsInArray(index: number) {
    return index >= 0 && index < this.assets.length;
  }

}
