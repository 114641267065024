import {Component, OnInit} from '@angular/core';
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {Button} from "primeng/button";
import {MessagesModule} from "primeng/messages";
import {Message} from "primeng/api";
import {FormsModule} from "@angular/forms";
import {LocalStorageKeys} from "../app.config";
import {NgIf} from "@angular/common";
import {CryptoService, LogPrefix, RegistryService, StringHelper} from "logbuch-client-sdk";
import {LoginMethod} from "logbuch.misc";
import validator from "validator";
import {AppService} from "../app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DialogModule} from "primeng/dialog";
import {CheckboxModule} from "primeng/checkbox";

@Component({
  selector: 'app-new-login',
  standalone: true,
  imports: [
    FloatLabelModule,
    InputTextModule,
    Button,
    MessagesModule,
    FormsModule,
    NgIf,
    ProgressSpinnerModule,
    DialogModule,
    CheckboxModule
  ],
  templateUrl: './new-login.component.html',
  styleUrl: './new-login.component.scss'
})
export class NewLoginComponent implements OnInit {

  legalModalVisible: boolean = false;

  messages: Message[] = [];
  methods: string[] = [];
  email: string = '';
  password: string = '';
  usePassword: boolean = false;
  acceptTerms: boolean = false;
  showMailButton: boolean = false;
  isBusy: boolean = false;

  get LoginMethod() {
    return LoginMethod;
  }

  get isValidMail() {
    return validator.isEmail(this.email);
  }

  constructor(private readonly services: RegistryService,
              private readonly crypto: CryptoService,
              private readonly appService: AppService,
              private readonly router: Router,
              private readonly route: ActivatedRoute) {
    //
  }

  async ngOnInit() {
    this.email = localStorage.getItem(LocalStorageKeys.LastMail) || '';

    const secret = this.route.snapshot.paramMap.get('secret');

    if (!StringHelper.isNullOrEmpty(secret)) {
      try {
        this.isBusy = true;
        await this.loginWithMail(secret!);
      } finally {
        this.isBusy = false;
      }
      return;
    }

    await this.appService.checkAuth();

    while (this.appService.authInProgress) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    if (this.appService.isAuth) {
      await this.router.navigate(['/journal']);
    }

    if (this.isValidMail) {
      await this.loadMethods();
    }
  }

  async loginButtonOnClick(forceMail = false) {
    this.clearValidationMessages();

    if (await this.validate()) {

      if (!await this.isAccountExists() && !this.acceptTerms) {
        this.legalModalVisible = true;
        return;
      }

      await this.autoLogin(forceMail);

    }
  }

  async keyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      await this.loginButtonOnClick();
    }
  }

  async autoLogin(forceMail = false) {
    this.isBusy = true;
    if (this.isMethodAllowed(LoginMethod.Passkey) && !forceMail && await this.loginWithFido2()) {
      this.isBusy = false;
      return;
    } else if (this.isMethodAllowed(LoginMethod.Password) && !forceMail) {
      if (this.usePassword && await this.passwordAuth()) {
        this.isBusy = false;
        return;
      } else if (!this.usePassword) {
        this.usePassword = true;
        this.isBusy = false;
        return;
      }
    } else if (this.isMethodAllowed(LoginMethod.Mail)) {
      this.usePassword = false;
      this.showMailButton = false;
      await this.requestLoginWithMail();
    }
    this.isBusy = false;
  }

  async loadMethods() {
    try {
      this.isBusy = true;
      this.methods = await this.services.auth.getAuthMethods(this.email);
      localStorage.setItem(LocalStorageKeys.LastMail, this.email);
    } catch (e) {
      console.warn(LogPrefix.W, e);
    } finally {
      this.isBusy = false;
    }
  }

  async isAccountExists() {
    try {
      this.isBusy = true;
      await this.services.auth.findAccount(this.email);
      return true;
    } catch (e) {
      console.warn(LogPrefix.W, e);
      return false
    } finally {
      this.isBusy = false;
    }
  }

  async sendPublicKey() {
    try {
      this.crypto.setKeys();
      while (this.crypto.clientPubKey == null) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      await this.services.auth.savePubKey(this.crypto.clientPubKey);
    } catch (e) {
      console.warn(LogPrefix.W, e);
    }
  }

  async loginWithFido2() {
    try {

      if (!StringHelper.isNullOrEmpty(this.password)) {
        return false;
      }

      if (await this.services.fido2.authClient(this.email)) {
        await Promise.all([
          this.sendPublicKey(),
          await this.afterLogin()
        ]);
      } else {
        throw new Error('FIDO2 authentication failed');
      }
      return true;
    } catch (e) {
      this.addValidationMessage('Die Anmeldung über deinen Passkey ist fehlgeschlagen, bitte versuche es mit einer anderen Methode.', 'error');
      console.warn(LogPrefix.W, e);
      this.showMailButton = true;
      return false;
    }
  }

  async validate() {
    this.clearValidationMessages();
    try {
      this.isBusy = true;
      if (!validator.isEmail(this.email)) {
        this.addValidationMessage('Bitte gib eine gültige E-Mail-Adresse ein.', 'error');
        return false;
      }
      await this.loadMethods();
      return true;
    } catch (e) {
      console.warn(LogPrefix.W, e);
      return false;
    } finally {
      this.isBusy = false;
    }
  }

  async passwordAuth() {
    const errorMessage = 'Deine E-Mail-Adresse oder dein Passwort scheinen ungültig zu sein.';

    if (!validator.isEmail(this.email) || this.password === '') {
      this.addValidationMessage(errorMessage, 'error');
      return false;
    }

    try {
      const session = await this.services.auth.login(this.email, this.password, true);
      localStorage.setItem('sessionID', session.ec ? this.crypto.decryptSingleValue(session.secret) : session.secret);

      await this.afterLogin();

      return true;
    } catch (e) {
      this.addValidationMessage(errorMessage, 'error');
      this.showMailButton = true;
      console.warn(LogPrefix.W, e);
    } finally {
      this.password = '';
    }

    return false;
  }

  async afterLogin() {
    localStorage.setItem('last_used_email', this.email.toLowerCase());

    await Promise.all([
      this.appService.checkAuth(),
      this.appService.updateStats()
    ]);

    await this.router.navigate(['/journal']);
  }

  async requestLoginWithMail() {
    try {
      await this.services.auth.requestLoginWithMail(this.email);
      this.addValidationMessage('Wir haben dir eine E-Mail mit einem Einmallink zum Login zugesendet.', 'success');
    } catch (e) {
      this.addValidationMessage('Es ist ein Fehler beim Anfordern des Logins aufgetreten, bitte überprüfe deine Eingaben.', 'error');
    }
  }

  async loginWithMail(secret: string) {
    try {
      const session = await this.services.auth.confirmLoginWithMail(secret, true);
      localStorage.setItem('sessionID', session.ec ? this.crypto.decryptSingleValue(session.secret) : session.secret);

      await Promise.all([
        this.sendPublicKey(),
        this.afterLogin()
      ]);

    } catch (e) {
      this.addValidationMessage('Der Link ist ungültig oder abgelaufen.', 'error');
    }
  }

  isMethodAllowed(method: LoginMethod) {
    return this.methods.some(m => m.toString() === method.toString());
  }

  addValidationMessage(message: string, severity = 'info') {
    this.messages = [
      ...this.messages,
      { severity, detail: message }
    ];
  }

  clearValidationMessages() {
    this.messages = [];
  }

  async toggleTermsAndConditions() {
    if (this.acceptTerms) {
      this.legalModalVisible = false;
      await this.loginButtonOnClick();
    }
  }

}
