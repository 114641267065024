import {Component, Injectable, OnInit} from '@angular/core';
import {LoaderService} from "../../loader/loader.service";
import {Subject} from "rxjs";
import {Gateway, RegistryService, UserDetails} from "logbuch-client-sdk";

@Injectable()
export class GatewayManagementService {

  propertyChanged: Subject<void> = new Subject<void>();

  visible: boolean = false;
  user?: UserDetails;
  gateways: Gateway[] = [];

  constructor(public services: RegistryService, public loader: LoaderService) {
    //
  }

  async open(user: UserDetails) {
    this.loader.visibility = true;
    this.gateways = await this.services.gateway.getGateways();
    this.user = user;
    for (const gateway of this.gateways) {
      this.setOwnerFlag(gateway);
      this.setMemberFlag(gateway);
    }
    this.visible = true;
    this.loader.visibility = false;
  }

  setOwnerFlag(gateway: Gateway) {
    gateway.isOwner = gateway.owner?.id === this.user?.id;
  }

  setMemberFlag(gateway: Gateway) {
    gateway.isMember = gateway.members?.find(m => m.id === this.user?.id) != null;
  }

  close() {
    this.visible = false;
  }


}
