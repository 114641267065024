import {Component, Input, OnInit,} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LoaderService} from "../../loader/loader.service";
import {EditModalComponent} from "../edit-modal/edit-modal.component";
import 'moment/locale/de';
import {SecurityQueryResult} from "../../securityQuery/securityQuery.component";
import {SecurityQueryService} from "../../securityQuery/securityQuery.service";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {PicturePreviewService} from "../../picture-preview/picture-preview.service";
import {VideoPlayerComponent} from "../../video-player/video-player.component";
import {DatepickerComponent} from "../../datepicker/datepicker.component";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AppService} from "../../app.service";
import {JournalStatsComponent} from "../../journal-stats/journal-stats.component";
import {QRCodeModule} from "angularx-qrcode";
import {FieldsetModule} from "primeng/fieldset";
import {EditorModule} from "primeng/editor";
import {TrackComponent} from "../../track/track.component";
import {AppConnectComponent} from "../app-connect/app-connect.component";
import {TrackOverlayComponent} from "../../track-overlay/track-overlay.component";
import {JournalEntityMetadataComponent} from "../journal-entity-metadata/journal-entity-metadata.component";
import {JournalCrewComponent} from "../journal-crew/journal-crew.component";
import {JournalEntitiesComponent} from "../journal-entities/journal-entities.component";
import {LogPrefix} from "../../app.config";
import {InputTextModule} from "primeng/inputtext";
import {FloatLabelModule} from "primeng/floatlabel";
import {ButtonModule} from "primeng/button";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {TooltipModule} from "primeng/tooltip";
import {JournalActionsComponent} from "../journal-actions/journal-actions.component";
import {JournalShareComponent} from "../journal-share/journal-share.component";
import {JournalPreviewComponent} from "../journal-preview/journal-preview.component";
import {PicturePreviewComponent} from "../../picture-preview/picture-preview.component";
import {
  CryptoService,
  Journal,
  JournalEntity,
  JournalStats,
  MapType,
  RegistryService,
  JournalService,
  AssetType,
  Track,
  Crew, ScrollService
} from "logbuch-client-sdk";

declare const mapkit: any;

@Component({
  selector: 'app-journal-editor',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    EditModalComponent,
    NgForOf,
    NgOptimizedImage,
    VideoPlayerComponent,
    DatepickerComponent,
    RouterLink,
    JournalStatsComponent,
    QRCodeModule,
    FieldsetModule,
    EditorModule,
    TrackComponent,
    AppConnectComponent,
    TrackOverlayComponent,
    JournalEntityMetadataComponent,
    JournalCrewComponent,
    JournalEntitiesComponent,
    InputTextModule,
    FloatLabelModule,
    ButtonModule,
    InputGroupModule,
    InputGroupAddonModule,
    TooltipModule,
    JournalActionsComponent,
    JournalShareComponent,
    JournalPreviewComponent,
    PicturePreviewComponent,
  ],
  templateUrl: './journal-editor.component.html',
  styleUrl: './journal-editor.component.scss'
})
export class JournalEditorComponent implements OnInit {

  journalId: number = -1;
  @Input() journal?: Journal;

  selectedEntity?: JournalEntity;
  selectedPreviewEntity?: JournalEntity;

  @Input() editMode = false;
  advancedOptions = false;

  deleteJournalEntities: number[] = [];

  stats?: JournalStats;

  showAppSecret = false;
  appSecret?: string;

  shareModalVisibility = false;

  previewModalVisibility = false;

  mapType?: MapType;
  mapKey?: string;

  selectedTrack?: Track;

  get qrCodeData() {
    return `MyShip-AssetUpload;${this.journalId}#${this.appSecret}`;
  }

  get AssetType() {
    return AssetType;
  }

  constructor(private route: ActivatedRoute,
              private journalService: JournalService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService,
              private alertService: AlertModalService,
              public previewService: PicturePreviewService,
              private router: Router,
              public appService: AppService,
              private services: RegistryService,
              private scrollService: ScrollService,
              private cryptoService: CryptoService) {
    this.route.params.subscribe((params: any) => {
      this.journalId = params.id;
      this.editMode = params.edit === '1'
    });
  }

  async ngOnInit() {
    try {
      this.scrollService.scrollToTop();
      this.loader.visibility = true;
      await Promise.all([
        this.loadMapDetails(),
        this.loadJournal()
      ]);
    } finally {
      this.loader.visibility = false;
    }
  }

  async loadMapDetails() {
    this.services.mapKit.getDetails().then(mapDetails => {
      this.mapType = mapDetails?.type;
      this.mapKey = mapDetails?.ec == true ? this.cryptoService.decryptSingleValue(mapDetails?.token!) : mapDetails?.token;
    });
  }

  async loadJournal(journal: Journal | undefined = this.journal ?? undefined) {
    this.loader.visibility = true;

    if (journal != null || this.journalId > 0) {
      const loadedJournal = await this.journalService.getJournal(journal == undefined ? this.journalId : journal.id);

      if (loadedJournal != null) {
        // const oldJournal = Object.assign(new Journal(), this.journal);
        this.journal = loadedJournal;

        // this.journal.readonly = this.journal.readonly || !this.editMode;

        for (const entity of this.journal.journals) {
          entity.date = new Date(entity.date);
          /* for (const asset of entity.assets) {
            if (asset.imageUrl == null) {
              asset.imageUrl = this.previewService.isImage(asset) ? 'assets/picture.svg' : 'assets/video-marketing.svg';
            }
          } */
        }

        this.sortJournals();

        if (this.journal.crew == null) {
          this.journal.crew = new Crew();
        }

        // Add Missing Assets
        /* if (oldJournal?.id != undefined) {
          for (const entity of this.journal.journals) {
            const oldEntity = oldJournal.journals.find(x => x.id == entity.id);
            if (oldEntity != null) {
              for (const asset of oldEntity.assets) {
                const newAsset = entity.assets.find(x => x.id == asset.id);
                if (newAsset == null) {
                  entity.assets.push(asset);
                } else if (asset.imageLoaded) {
                  newAsset.imageUrl = asset.imageUrl;
                }
              }
            }
          }
        } else {

          this.setDefaultThumbnail();

          this.loader.visibility = false;

          for (const entity of this.journal.journals) {
            try {
              entity.assetsLoaded = true;
              entity.isVisible = true;
              this.previewService.setAssetUrls(entity, true).then();
            } catch (e) {
              console.log(`${LogPrefix.E} Asset could not be loaded: ${e}`);
            }
            await new Promise(resolve => setTimeout(resolve, 2000));
          }

        } */

      }
    }

    this.loader.visibility = false;
  }

  crewSelected(crew: Crew) {
    this.journal!.crew = crew;
    this.save().then();
  }

  sortJournals() {
    this.journal?.journals.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }

  async save() {
    let refreshPage = false;

    this.loader.visibility = true;

    // Delete Assets
    for (const entityId of this.deleteJournalEntities) {
      refreshPage = true;
      const entity = this.journal!.journals.find(x => x.id == entityId);
      if (entity != null && entity.assets != null) {
        try {
          for (const asset of entity.assets) {
            await this.journalService.deleteAsset(asset.id);
          }
          await this.journalService.deleteEntity(entity.id);
        } catch (e) {
          this.alertService.show('Es ist ein Fehler beim Löschen der Einträge aufgetreten. Bitte versuchen Sie es erneut.');
          this.loader.visibility = false;
          return;
        }
      } else {
        this.alertService.show('Es sind Einträge zur Löschung markiert, die nicht existieren.');
        this.loader.visibility = false;
        return;
      }
    }

    if (this.journal?.journals.some(x => x.id == -1)) {
      refreshPage = true;
    }

    try {
      const journal = await this.journalService.putJournal(this.journal!);

      if (journal !== undefined) {

        /* journal.journals.forEach(e => {
          e.assets = this.journal?.journals.find(x => x.id == e.id)?.assets!;
        }); */

        this.journal!.journals.forEach(e => {
          if (this.deleteJournalEntities.includes(e.id)) {
            const index = this.journal!.journals.indexOf(e);
            this.journal!.journals.splice(index, 1);
          }
        });

        this.sortJournals();

        if (this.journal?.crew != null) {
          this.journal.crew.skipper = journal.crew?.skipper;
          this.journal.crew.coSkipper = journal.crew?.coSkipper;
          this.journal.crew.navigator = journal.crew?.navigator;
          this.journal.crew.smutje = journal.crew?.smutje;
          this.journal.crew.crewMembers = journal.crew?.crewMembers ?? [];
        }

        this.appService.isDirty = false;
        this.editMode = false;
      }
    } catch (e) {
      console.log(`${LogPrefix.E} Error while saving journal: ${e}`);
    } finally {
      this.deleteJournalEntities = [];
      this.loader.visibility = false;
      if (refreshPage) {
        window.location.reload();
      }
    }
  }

  async delete() {
    const title = this.journal!.revier != null && this.journal?.revier != '' ? this.journal?.revier : 'unbenannt';
    if (await this.securityQuery.show(`Möchten Sie die Reise: ${title} wirkslich aus dem System löschen? Diese Aktion kann nicht rückgängig gemacht werden.`, true, true, false)
      == SecurityQueryResult.Yes) {
      try {
        this.loader.visibility = true;
        await this.journalService.deleteJournal(this.journal!.id);
        await this.router.navigate(['/journal']);
      } finally {
        this.loader.visibility = false;
      }
    }
  }

  async deleteAssets(assetType?: AssetType) {
    const title = this.journal!.revier != null && this.journal?.revier != '' ? this.journal?.revier : 'unbenannt';
    const assetTypeString = assetType === undefined ? 'Fotos & Videos' : assetType === AssetType.Image ? 'Fotos' : 'Videos';
    if (await this.securityQuery.show(`Möchtest du wirklich alle ${assetTypeString} der Reise: ${title} wirklich aus dem System löschen? Diese Aktion kann nicht rückgängig gemacht werden.`, true, true, false)
      == SecurityQueryResult.Yes) {
      this.loader.visibility = true;
      try {
        await this.journalService.deleteAssetsByJournal(this.journal!.id, assetType);
        if (assetType === undefined) {
          this.journal?.journals.map(x => x.assets = []);
        } else if (assetType === AssetType.Image) {
          this.journal?.journals.map(x => x.assets = x.assets.filter(x => !this.previewService.isImage(x)));
        } else {
          this.journal?.journals.map(x => x.assets = x.assets.filter(x => !this.previewService.isVideo(x)));
        }
      } catch (e) {
        //
      } finally {
        this.loader.visibility = false;
        window.location.reload();
      }
    }
  }

  async closeEditor(fileUploaded: boolean) {
    if (fileUploaded) {
      await this.refreshAssets();
    }

    this.selectedEntity = undefined;
  }

  async refreshAssets(entity: JournalEntity | undefined = undefined) {
    try {
      if (this.selectedEntity !== undefined || entity !== undefined) {
        this.loader.visibility = true;
        if (entity !== undefined) {
          entity.assets = await this.journalService.getAssetsByEntity(entity.id);
        } else {
          this.selectedEntity!.assets = await this.journalService.getAssetsByEntity(this.selectedEntity!.id);
        }
      }
    } finally {
      this.loader.visibility = false;
      window.location.reload();
    }
  }

  async processImages() {

    if (await this.securityQuery.show('Möchten Sie die Bilder verarbeiten? Dieser Vorgang kann einige Zeit in Anspruch nehmen.', true, true, false) != SecurityQueryResult.Yes) {
      return;
    }

    try {
      await this.journalService.processImageResolutions(this.journal!.id);
      this.alertService.show('Die Bilder werden nun verarbeitet, dies kann einige Zeit in Anspruch nehmen. Sie werden per E-Mail benachrichtigt, sobald die Verarbeitung abgeschlossen ist.', 8000, AlertType.Success);
    } catch (e) {
      this.alertService.show('Es ist ein Fehler beim Verarbeiten der Bilder aufgetreten. Bitte versuchen Sie es erneut.', 8000, AlertType.Error);
    }

  }

  async showAppOverlay() {
    if (this.appSecret != null && this.appSecret != '') {
      this.showAppSecret = true;
      return;
    }
    this.loader.visibility = true;
    this.appSecret = (await this.journalService.getAppSecret(this.journal!.id)).secret;
    this.loader.visibility = false;
    if (this.appSecret != null && this.appSecret != '') {
      this.showAppSecret = true;
    } else {
      this.alertService.show('Es ist ein Fehler beim Laden des QR-Codes aufgetreten. Bitte versuchen Sie es erneut.');
    }
  }

  async closePreview() {
    await this.refreshAssets(this.selectedPreviewEntity);
    this.previewService.assets = [];
  }

  async crewCreated(crew: Crew) {
    this.journal!.crew = crew;
    await this.save();
  }

}
