import {
  AfterViewInit, Component, EventEmitter, Input, Output,
} from '@angular/core';
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {InputTextModule} from "primeng/inputtext";
import {JournalPreviewMapComponent} from "../journal-preview-map/journal-preview-map.component";
import {TooltipModule} from "primeng/tooltip";
import {JournalPreviewMapService} from "../journal-preview-map/journal-preview-map.service";
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import 'moment/locale/de';
import {DialogModule} from "primeng/dialog";
import {Coordinate} from "ol/coordinate";
import {Title} from "@angular/platform-browser";
import ol from 'ol';
import {GalleriaModule} from "primeng/galleria";
import {JournalPreviewHeaderComponent} from "../journal-preview-header/journal-preview-header.component";
import {JournalPreviewEntityListComponent} from "../journal-preview-entity-list/journal-preview-entity-list.component";
import {LoaderService} from "../../loader/loader.service";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {JournalCrewComponent} from "../../journal/journal-crew/journal-crew.component";
import {Button} from "primeng/button";
import {FooterComponent} from "../../footer/footer.component";
import {MenuModule} from "primeng/menu";
import {ReactiveFormsModule} from "@angular/forms";
import {JournalEntityMetadataComponent} from "../../journal/journal-entity-metadata/journal-entity-metadata.component";
import {JournalEntitiesComponent} from "../../journal/journal-entities/journal-entities.component";
import {ExternalService, Journal, JournalEntity, Track, JournalService, SocketService, ExtractDataService} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-preview-core',
  standalone: true,
  imports: [
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    JournalPreviewMapComponent,
    TooltipModule,
    NgForOf,
    NgIf,
    DialogModule,
    NgClass,
    GalleriaModule,
    JournalPreviewHeaderComponent,
    JournalPreviewEntityListComponent,
    JournalCrewComponent,
    Button,
    FooterComponent,
    MenuModule,
    ReactiveFormsModule,
    RouterLink,
    RouterOutlet,
    JournalEntityMetadataComponent,
    JournalEntitiesComponent,
  ],
  templateUrl: './journal-preview-core.component.html',
  styleUrl: './journal-preview-core.component.scss'
})
export class JournalPreviewCoreComponent implements AfterViewInit {

  @Input() journalPermalink?: string;
  @Input() journalId?: number;

  @Output() onExit = new EventEmitter<void>();

  flagIcon = 'assets/world.svg';

  journal?: Journal;
  maps = new Map<number, ol.Map>();

  allCords: Coordinate[] = [];

  selectedEntity?: JournalEntity;

  get entities(): JournalEntity[] {
    return this.journal?.journals ?? [];
  }

  constructor(private mapService: JournalPreviewMapService,
              private externalService: ExternalService,
              private journalService: JournalService,
              private router: Router,
              private titleService: Title,
              private loader: LoaderService,
              private socket: SocketService,
              private alertService: AlertModalService,
              private extract: ExtractDataService) {
    //
  }

  async ngAfterViewInit() {
    this.loader.visibility = true;

    if (this.journalId === undefined && this.journalPermalink === undefined) {
      await this.router.navigate(['/']);
      return;
    }

    try {
      this.journal = this.journalId !== undefined && this.journalId > 0
        ? await this.journalService.getJournal(this.journalId)
        : await this.journalService.getJournalByLink(this.journalPermalink!);

      this.titleService.setTitle(this.journal?.title ?? 'Logbuch');

      // Sort Entities by date
      this.journal.journals.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      this.setCountryFlag(this.journal?.country).then();
      this.loadMap().then();
    } catch (e) {
      this.alertService.show('Das Logbuch konnte nicht gefunden werden.');
      console.log(e);
      await this.router.navigate(['/']);
    } finally {
      this.loader.visibility = false;
      this.loadTracks().then();
    }
  }

  async loadMap() {
    this.mapService.resetMap();
    if (this.journal !== undefined) {
      const coords: Coordinate[] = [];
      for (const entity of this.journal.journals) {
        const firstAsset = entity.assets.find(x => x.latitude !== -1 && x.longitude !== -1);
        if (firstAsset !== undefined && this.mapService.map === undefined) {
          await this.mapService.init(firstAsset.latitude, firstAsset.longitude);
        }
        if (firstAsset !== undefined && this.mapService.map !== undefined) {
          this.mapService.setMarker(this.mapService.map, firstAsset.latitude, firstAsset.longitude, false);
        }
        for (const asset of entity.assets) {
          if (asset.latitude !== -1 && asset.longitude !== -1) {
            coords.push([asset.longitude, asset.latitude]);
          }
        }
      }

      if (this.mapService.map !== undefined) {
        this.mapService.centerMapOnCoordinates(this.mapService.map, coords);
      }
    }
  }

  async loadTracks() {
    if (this.journal !== undefined) {
      while (!this.socket.state) {
        await new Promise<void>(r => setTimeout(r, 200));
      }
      this.socket.client.on('track:one', (track: Track) => {
        // console.log(track);
        const date = new Date(track.createdAt);
        date.setHours(0, 0, 0, 0);
        if (this.journal !== undefined) {
          for (const journal of this.journal.journals) {
            const entityDate = new Date(journal.date);
            entityDate.setHours(0, 0, 0, 0);
            if (entityDate.getTime() === date.getTime()) {
              const map = this.maps.get(journal.id);
              if (map !== undefined) {
                const cords: Coordinate[] = [];
                for (const record of track.records) {
                  const lat = this.extract.getLatitude(record.data);
                  const lon = this.extract.getLongitude(record.data);
                  if (lat !== undefined && lon !== undefined) {
                    cords.push([lon, lat]);
                    this.allCords.push([lon, lat]);
                  }
                }
                let lastCord: Coordinate | undefined = undefined;
                for (const cord of cords) {
                  if (lastCord === undefined) {
                    lastCord = cord;
                    continue;
                  }
                  const line = this.mapService.drawLine([lastCord[0], lastCord[1]], [cord[0], cord[1]]);
                  if (line !== null) {
                    map.addLayer(line);
                    this.mapService.map?.addLayer(line);
                  }
                  lastCord = cord;
                }
                this.mapService.centerMapOnCoordinates(map, cords);
              }
            }
          }
        }
      });
      try {
        await this.extract.init();
        await this.journalService.getJournalTracks(this.journal);
      } catch (e) {
        //
      }
    }
  }

  imageClick(index: number, entity: JournalEntity) {
    this.selectedEntity = entity;
  }

  async setCountryFlag(country?: string) {
    try {
      if (country !== undefined && country !== '' && country.length > 3) {
        const result = await this.externalService.getCountries(country);
        for (const country of result) {
          this.flagIcon = country.flags.svg;
        }
      }
    } catch (e) {
      //
    }
  }

}
