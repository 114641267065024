import {ApplicationConfig, ChangeDetectorRef} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import { provideHttpClient } from "@angular/common/http";
import {AppService} from "./app.service";
import {SortService} from "./sort/sort.service";
import {AlertModalService} from "./alert-modal/alert-modal.service";
import {LoaderService} from "./loader/loader.service";
import {SecurityQueryService} from "./securityQuery/securityQuery.service";
import {PicturePreviewService} from "./picture-preview/picture-preview.service";
import {CrewEditorService} from "./crew-editor/crew-editor.service";
import {GatewayManagementService} from "./admin/gateway-management/gateway-management.service";
import {VideoPlayerService} from "./video-player/video-player.service";
import {MapService} from "./map/map.service";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {VerifySessionService} from "./verfiy-session/verify-session.service";
import {JournalPreviewMapService} from "./journal-preview/journal-preview-map/journal-preview-map.service";
import {provideClientSdk} from "logbuch-client-sdk";
import {environment} from "../environments/environment";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideClientSdk(
      `${environment.backend.protocol}://${environment.backend.host}:${environment.backend.port}`,
      '/api/v1',
      true,
      true
    ),
    AppService,
    SortService,
    AlertModalService,
    LoaderService,
    SecurityQueryService,
    PicturePreviewService,
    CrewEditorService,
    GatewayManagementService,
    VideoPlayerService,
    MapService,
    VerifySessionService,
    JournalPreviewMapService,
  ]
};

export const LogPrefix = {
  I: 'VDR_I',
  E: 'VDR_E',
  W: 'VDR_W',
  D: 'VDR_D'
};

export const LocalStorageKeys = {
  LastMail: 'last_used_email'
}
