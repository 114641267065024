import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {GatewayManagementComponent} from "../gateway-management/gateway-management.component";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ProgressBarModule} from "primeng/progressbar";
import {ButtonModule} from "primeng/button";
import {LoaderService} from "../../loader/loader.service";
import {AlertModalService, AlertType} from "../../alert-modal/alert-modal.service";
import {PermissionType, RegistryService} from "logbuch-client-sdk";

@Component({
  selector: 'app-jobs-management',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    NgClass,
    GatewayManagementComponent,
    TableModule,
    InputTextModule,
    ProgressBarModule,
    ButtonModule
  ],
  templateUrl: './jobs-management.component.html',
  styleUrl: './jobs-management.component.scss'
})
export class JobsManagementComponent implements OnInit {

  jobs: Job[] = [
    new Job(
      'GPS - EXIF',
      'Dieser Job stößt eine erneute Überprüfung der Bilder an, um ggf. die GPS-Informationen aus der EXIF-Daten zu korrigieren.',
      'images/gps'),
    new Job(
      'Speicherplatz berechnen',
      'Dieser Job stößt eine erneute Überprüfung des verbrauchten Speicherplatzes an.',
      'calculateStorage'),
    new Job(
      'Größenkorrektur der Bilder',
      'Dieser Job stößt eine erneute Überprüfung der Bilder an, um ggf. die Größe der Bilder zu korrigieren.',
      'images/journal'),
    new Job(
      'Crew - Duplikate',
      'Dieser Job stößt eine erneute Überprüfung der Crew-Mitglieder an, um ggf. Duplikate zu entfernen.',
      'crew/checkDuplicates'),
    new Job(
      'Thumbnail-Generierung',
      'Dieser Job stößt eine erneute Überprüfung der Thumbnails an, um ggf. Thumbnails zu generieren.',
      'thumbnails/generate'),
    new Job(
      'Dummy-Journal',
      'Dieser Job erstellt ein Dummy-Journal.',
      'dummyJournal'),
    new Job(
      'Überprüfung der Berechtigungen der Crews',
      'Dieser Job stößt eine erneute Überprüfung der Berechtigungen der Crew-Mitglieder an.',
      'crew/checkPermissions')
  ];

  constructor(private services: RegistryService, private router: Router, private loader: LoaderService, private alert: AlertModalService) {
    //
  }

  async ngOnInit() {
    if (!await this.services.permission.checkReadable(PermissionType.Administrator)) {
      await this.router.navigate(['/login']);
    }
  }

  async executeJob(job: Job) {
    try {
      this.loader.visibility = true;
      await this.services.admin.runJob(job.path);
      this.alert.show('Der Job wurde erfolgreich angestoßen.', 6000, AlertType.Success);
    } finally {
      this.loader.visibility = false;
    }
  }

}

export class Job {
  constructor(public name: string, public description: string, public path: string) {
    //
  }
}
