import {AfterViewInit, Component} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {InputOtpModule} from "primeng/inputotp";
import {NgForOf, NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {QRCodeModule} from "angularx-qrcode";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import {MessagesModule} from "primeng/messages";
import {ProgressBarModule} from "primeng/progressbar";
import {InputMaskModule} from "primeng/inputmask";
import {Message} from "primeng/api";
import validator from "validator";
import {SecurityQueryService} from "../../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../../securityQuery/securityQuery.component";
import {UserDetails, UserDetailsService, UserService, AuthService} from "logbuch-client-sdk";

@Component({
  selector: 'app-settings-data-tab',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    NgIf,
    InputTextModule,
    QRCodeModule,
    NgForOf,
    ProgressBarModule,
    InputMaskModule,
    MessagesModule,
    InputOtpModule,
  ],
  templateUrl: './settings-data-tab.component.html',
  styleUrl: './settings-data-tab.component.scss'
})
export class SettingsDataTabComponent implements AfterViewInit {

  user?: UserDetails;

  messages: Message[] = [];

  phoneVerified?: boolean;
  phoneVerificationCodeRequested = false;
  phoneDeleteRequested = false;
  phoneVerificationCode: string = '';
  phoneExists = false;

  // ChangeMail PopUp-Properties
  changeMailDisplayed = false;
  changeMailNewAddress = '';
  changeMailOldAddressCode = '';
  changeMailNewAddressCode = '';
  changeMailOldAddressCodeRequested = false;

  constructor(private authService: AuthService,
              private alertService: AlertModalService,
              private userDetailsService: UserDetailsService,
              private userServices: UserService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService) {
    //
  }

  async ngAfterViewInit() {
    try {
      this.user = await this.userDetailsService.getUserDetails() ?? undefined;
      this.phoneVerified = this.user.phoneVerified;
      this.phoneExists = this.user.phone !== null && this.user.phone !== '' && this.user.phone !== undefined;

      if (!this.user.phoneVerified && this.user.phone !== null && this.user.phone !== '' && (this.user.phone !== undefined || this.user.phone !== '')) {
        this.messages = [
          {
            key: 'phoneNotVerified',
            severity: 'info',
            detail: 'Deine Telefonnummer wurde noch nicht verifiziert.',
            icon: 'pi pi-exclamation-triangle'
          }
        ];
      }

    } catch (e) {
      //
    }
  }

  async updateData() {

    if (this.user === undefined) {
      return;
    }

    this.loader.visibility = true;
    try {
      await this.userDetailsService.putUserDetails(this.user);
    } finally {
      this.loader.visibility = false;
      await this.ngAfterViewInit();
    }
  }

  async requestPhoneVerificationCode() {
    this.loader.visibility = true;
    try {
      if (this.phoneVerificationCodeRequested) {
        await this.userDetailsService.confirmPhoneVerify(this.phoneVerificationCode);
        this.phoneVerified = true;
        this.phoneVerificationCodeRequested = false;
        this.messages = this.messages.filter(m => m.key !== 'phoneNotVerified');
      } else {
        await this.userDetailsService.requestPhoneVerify();
        this.phoneVerificationCodeRequested = true;
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  async deletePhoneRequest() {
    this.loader.visibility = true;
    try {
      if (this.phoneDeleteRequested) {
        await this.userDetailsService.deletePhone(this.phoneVerificationCode);
        this.phoneVerified = false;
        this.user!.phone = '';
        this.phoneVerificationCodeRequested = false;
        this.phoneDeleteRequested = false;
      } else {
        await this.userDetailsService.requestPhoneVerify();
        this.phoneDeleteRequested = true;
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  paste(event: ClipboardEvent, type: "old" | "new") {
    navigator.clipboard.readText().then(
      text => {
        if (text.length !== 6 && !validator.isNumeric(text.toString())) {
          return;
        }
        switch (type) {
          case "old":
            this.changeMailOldAddressCode = text;
            break;
          case "new":
            this.changeMailNewAddressCode = text;
            break;
        }
      }
    );
  }

  async closeChangeMail() {
    if (await this.securityQuery.show('Möchtest du den Vorgang wirklich abbrechen?', true, true, false) === SecurityQueryResult.Yes) {
      this.disposeMailChange();
    }
  }

  async startChangeMail() {
    try {
      this.loader.visibility = true;
      await this.userServices.changeMail(this.changeMailNewAddress);
      this.changeMailOldAddressCodeRequested = true;
    } finally {
      this.loader.visibility = false;
    }
  }

  async confirmChangeMail() {
    this.loader.visibility = true;

    let error = false;

    try {
      await this.userServices.confirmMail(+this.changeMailOldAddressCode);
    } catch (e) {
      this.changeMailOldAddressCode = '';
      error = true;
    }

    try {
      await this.userServices.confirmMail(+this.changeMailNewAddressCode);
    } catch (e) {
      this.changeMailNewAddressCode = '';
      error = true;
    }

    if (!error) {
      this.disposeMailChange();
      this.user = await this.userDetailsService.getUserDetails() ?? undefined;
      localStorage.setItem('last_used_email', this.user?.user?.email ?? '');
    }

    this.loader.visibility = false;
  }

  private disposeMailChange() {
    this.changeMailDisplayed = false;
    this.changeMailNewAddress = '';
    this.changeMailOldAddressCode = '';
    this.changeMailNewAddressCode = '';
    this.changeMailOldAddressCodeRequested = false
  }

}
