import {Component, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppService} from "../app.service";
import {AlertModalService, AlertType} from "../alert-modal/alert-modal.service";
import {Router, RouterLink} from "@angular/router";
import {ToggleButtonComponent} from "../toggle-button/toggle-button.component";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {TabViewModule} from "primeng/tabview";
import {ButtonModule} from "primeng/button";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {MessageModule} from "primeng/message";
import {SelectButtonModule} from "primeng/selectbutton";
import {RadioButtonModule} from "primeng/radiobutton";
import {LoaderService} from "../loader/loader.service";
import {FileUploadModule} from "primeng/fileupload";
import {SettingsSecurityTabComponent} from "./settings-security-tab/settings-security-tab.component";
import {SettingsDataTabComponent} from "./settings-data-tab/settings-data-tab.component";
import {SettingsGeneralTabComponent} from "./settings-general-tab/settings-general-tab.component";
import {SettingsSystemTabComponent} from "./settings-system-tab/settings-system-tab.component";
import {UserManagementComponent} from "../admin/user-management/user-management.component";
import {MembershipManagementComponent} from "../admin/membership-management/membership-management.component";
import {ErrorManagementComponent} from "../admin/error-management/error-management.component";
import {GatewayManagementComponent} from "../admin/gateway-management/gateway-management.component";
import {TrackDataKeyManagementComponent} from "../admin/trackDataKey-management/trackDataKey-management.component";
import {JobsManagementComponent} from "../admin/jobs-management/jobs-management.component";
import {CmsManagementComponent} from "../admin/cms-management/cms-management.component";
import {PermissionType, MapType, PermissionService, UserService, AuthService, TestService} from "logbuch-client-sdk";

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    RouterLink,
    ToggleButtonComponent,
    TabViewModule,
    ButtonModule,
    FloatLabelModule,
    InputTextModule,
    MessageModule,
    SelectButtonModule,
    RadioButtonModule,
    FileUploadModule,
    SettingsSecurityTabComponent,
    SettingsDataTabComponent,
    SettingsGeneralTabComponent,
    SettingsSystemTabComponent,
    UserManagementComponent,
    MembershipManagementComponent,
    ErrorManagementComponent,
    GatewayManagementComponent,
    TrackDataKeyManagementComponent,
    JobsManagementComponent,
    NgClass,
    CmsManagementComponent
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {

  selectedTab = SelectedTab.Data;

  pw1 = '';
  pw2 = '';

  firstname = '';
  lastname = '';
  phone = '';
  mapType?: MapType;

  isAdmin = false;
  canEditCMS = false;

  selectionActive = false;

  /* stripe?: Stripe | null;
  elements?: StripeElements; */

  get SelectedTab() {
    return SelectedTab;
  }

  constructor(public appService: AppService,
              private alertService: AlertModalService,
              private securityQuery: SecurityQueryService,
              private permissionService: PermissionService,
              private authService: AuthService,
              private testService: TestService,
              private userService: UserService,
              private router: Router,
              private loader: LoaderService) {
    this.fillData();
    appService.userLoaded.subscribe(() => {
      this.fillData();
    });
  }

  async ngOnInit() {
    this.loader.visibility = true;
    this.isAdmin = await this.permissionService.checkReadable(PermissionType.Administrator);
    this.canEditCMS = await this.permissionService.checkWriteable(PermissionType.PortfolioManagement);
    this.loader.visibility = false;
    // this.stripe = await loadStripe(environment.stripe.publishableKey);
  }

  fillData() {
    this.firstname = this.appService.userDetails?.firstname ?? '';
    this.lastname = this.appService.userDetails?.lastname ?? '';
    this.phone = this.appService.userDetails?.phone ?? '';
    this.mapType = this.appService.userDetails?.mapType;
  }

  async setPassword() {
    if (this.pw1 == '' || this.pw2 == '') {
      this.alertService.show('Bitte füllen Sie alle Eingabefelder aus!');
    }

    if (this.pw1 != this.pw2) {
      this.alertService.show('Die neuen Passwörter stimmen nicht überein!');
    }

    try {
      await this.userService.changePassword(this.pw1)
    } catch (e) {
      this.alertService.show('Es ist ein Fehler aufgetreten, dass Passwort konnte nicht geändert werden.');
    } finally {
      this.pw1 = '';
      this.pw2 = '';
    }

  }

  async logout() {
    await this.authService.logout();
    this.appService.isAuth = false;
    await this.router.navigate(['/login']);
  }

  /* Stripe Section

  async initMembershipPayment() {
    this.loader.visibility = true;
    const paymentIntent = await this.services.payment.initMembershipPayment(1);

    if (paymentIntent !== undefined) {
      const appearance = {
        theme: 'stripe',
      };

      this.elements = this.stripe?.elements({
        clientSecret: paymentIntent?.client_secret,
      });

      const paymentElementOptions = {
        layout: "tabs",
      };

      const paymentElement = this.elements!.create("payment");
      paymentElement.mount("#payment-element");
    }

    this.loader.visibility = false;
  }

  async handleSubmit(e: any) {
    this.loader.visibility = true;

    const {error} = await this.stripe!.confirmPayment({
      elements: this.elements!,
      confirmParams: {
        return_url: "http://localhost:4200/settings",
      },
      redirect: undefined,
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      this.alertService.show(error.message ?? "An unexpected error occurred.");
    } else {
      this.alertService.show("An unexpected error occurred.");
    }

    this.loader.visibility = false;
  }

  */

  async testJournalGenerate(event: any) {
    this.loader.visibility = true;
    const file = event.files[0];
    console.log(file);
    await this.testService.createDemoJournals(file);
    this.loader.visibility = false;
  }

  protected readonly MapType = MapType;
}

export enum SelectedTab {
  Security,
  Data,
  Settings,
  System,
  UserManagement,
  MembershipManagement,
  ErrorManagement,
  TrackDataKeyManagement,
  Jobs,
  CMS
}
