import {Component, OnInit} from '@angular/core';
import {KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SortComponent} from "../sort/sort.component";
import {AppService} from "../app.service";
import {Router, RouterLink} from "@angular/router";
import {LoaderService} from "../loader/loader.service";
import {CreationComponent} from "../journal/creation/creation.component";
import {JournalEditorComponent} from "../journal/journal-editor/journal-editor.component";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";
import {JournalPreviewComponent} from "../journal/journal-preview/journal-preview.component";
import {AlertModalService, AlertType} from "../alert-modal/alert-modal.service";
import {Journal, RegistryService, BaseService, JournalService, ScrollService} from "logbuch-client-sdk";

@Component({
  selector: 'app-journals',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    FormsModule,
    NgIf,
    SortComponent,
    CreationComponent,
    JournalEditorComponent,
    RouterLink,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TooltipModule,
    JournalPreviewComponent,
    KeyValuePipe
  ],
  templateUrl: './new-journal-overview.component.html',
  styleUrl: './new-journal-overview.component.scss'
})
export class NewJournalOverviewComponent implements OnInit {

  creationModal = false;
  selectedJournal?: Journal;

  page = 1;
  take = 10;
  search = '';
  searchFieldVisibility = false;

  journals?: Journal[];
  filteredJournals?: Journal[];
  sortedJournals?: JournalList[];

  previewJournal?: Journal;
  showJournalPreview = false;

  fallbackFlagIcon = 'assets/world.svg';

  countries = new Map<string, string>();

  constructor(private appService: AppService,
              private router: Router,
              private journalService: JournalService,
              public loader: LoaderService,
              private services: RegistryService,
              private base: BaseService,
              private scrollService: ScrollService,
              private alert: AlertModalService) {
    this.appService.navigate.subscribe(() => {
      this.selectedJournal = undefined;
    })
  }

  async ngOnInit() {
    this.scrollService.scrollToTop();
    this.base.selectedGateway = -1;
    this.loader.visibility = true;
    await this.loadJournals();
    this.appService.searchChanged.subscribe((searchValue) => {
      this.searchChanged(searchValue);
    });
    this.loader.visibility = false;
  }

  async loadJournals(id: number = -1) {
    try {

      this.loader.visibility = true;

      if (id > 0) {
        const journal = await this.journalService.getJournal(id);
        if (journal !== undefined) {
          await this.openJournal(journal, true);
          return;
        }
      }

      this.journals = await this.journalService.getJournals();
      this.filteredJournals = this.journals;

      this.sortJournals();
      this.groupJournalsByYear();

      await this.setCountryFlagIcon();
    } finally {
      this.loader.visibility = false;
    }
  }

  sortJournals() {
    // Sort Journals by Year
    this.filteredJournals = this.filteredJournals?.sort((a, b) => {
      if (a.year !== undefined && b.year !== undefined) {
        return b.year - a.year;
      } else {
        return 0;
      }
    });
  }

  groupJournalsByYear() {
    this.sortedJournals = [];
    for (const journal of this.filteredJournals ?? []) {
      if (journal.year === undefined) {
        journal.year = 0;
      }
      const journalItem = this.sortedJournals.find(j => j.year === journal.year);
      if (journalItem !== undefined) {
        journalItem.journals.push(journal);
      } else {
        this.sortedJournals.push(new JournalList(journal.year, [journal]));
      }
    }
  }

  async setCountryFlagIcon() {
    this.countries.clear();
    for (const journal of this.journals ?? []) {
      if (journal.country !== undefined && journal.country !== '') {
        const icon = await this.setCountryFlag(journal);
        this.countries.set(journal.country, icon);
      }
    }
  }

  getCountryFlagIcon(country?: string) {
    if (country === undefined || country === '') {
      return this.fallbackFlagIcon;
    }
    return this.countries.get(country) ?? this.fallbackFlagIcon;
  }

  searchChanged(searchValue: string) {
    this.search = searchValue;
    if (searchValue === '') {
      this.filteredJournals = this.journals ?? [];
    }
    this.filteredJournals = this.journals?.filter(j => {
      return j.title?.toLowerCase().includes(searchValue.toLowerCase()) || j.year?.toString().includes(searchValue.toLowerCase()) || j.country?.toLowerCase().includes(searchValue.toLowerCase())
        || j.miles?.toString().includes(searchValue.toLowerCase()) || j.boatName?.toLowerCase().includes(searchValue.toLowerCase()) || j.boatType?.toLowerCase().includes(searchValue.toLowerCase())
        || j.revier?.toLowerCase().includes(searchValue.toLowerCase());
    }) ?? [];
    this.sortJournals();
    this.groupJournalsByYear();
  }

  async openJournal(journal: Journal, edit: boolean) {
    await this.router.navigate([
      '/journal', journal.id, edit ? '1' : '0'
    ]);

    /* if (edit) {
      await this.router.navigate([
        '/journal', journal.id, edit ? '1' : '0'
      ]);
    } else {
      this.previewJournal = journal;
      this.showJournalPreview = true;
    } */
  }

  async setCountryFlag(journal: Journal) {
    try {
      if (journal.country !== undefined && journal.country !== '' && journal.country.length > 3) {
        const result = await this.services.external.getCountries(journal.country);
        for (const country of result) {
          return country.flags.svg;
        }
      }
    } catch (e) {
      //
    }
    return this.fallbackFlagIcon;
  }

  async createDummyJournal() {
    try {
      this.loader.visibility = true;
      await this.services.admin.runJob('dummyJournal');
      this.alert.show('Ein Beispiel-Törn wurde für dich angelegt.', 6000, AlertType.Success);
        await this.loadJournals();
    } finally {
      this.loader.visibility = false;
    }
  }
}

export class JournalList {
  constructor(public year: number, public journals: Journal[]) {
    //
  }
}
