"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnumHelper = void 0;
class EnumHelper {
  // Convert number[] to enum[]
  static toEnumArray(enumType, values) {
    return values.map(value => enumType[value]);
  }
}
exports.EnumHelper = EnumHelper;