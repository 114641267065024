import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {TrackComponent} from "../track/track.component";
import {Track} from "logbuch-client-sdk";

@Component({
  selector: 'app-track-overlay',
  standalone: true,
  imports: [
    NgIf,
    TrackComponent
  ],
  templateUrl: './track-overlay.component.html',
  styleUrl: './track-overlay.component.scss'
})
export class TrackOverlayComponent {

  @Input() selectedTrack?: Track;

}
