import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {PicturePreviewService} from "../../picture-preview/picture-preview.service";
import {environment} from "../../../environments/environment";
import moment from "moment";
import {JournalEntityAsset} from "logbuch-client-sdk";

@Component({
  selector: 'app-journal-entity-assets',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './journal-entity-assets.component.html',
  styleUrl: './journal-entity-assets.component.scss'
})
export class JournalEntityAssetsComponent {

  @Input() assets: JournalEntityAsset[] = [];

  @Output() onAssetClick = new EventEmitter<JournalEntityAsset>();
  @Output() onAssetDelete = new EventEmitter<JournalEntityAsset>();
  @Output() onAssetPutThumbnail = new EventEmitter<JournalEntityAsset>();

  get sortedAssets() {
    return this.assets.sort((a, b) => new Date(b.date ?? new Date()).getTime() - new Date(a.date ?? new Date()).getTime());
  }

  constructor(public previewService: PicturePreviewService) {
    //
  }

  getAssetUrl(asset: JournalEntityAsset, quality: "sm" | "md" | "lg" = "sm") {
    return `${environment.backend.protocol}://${environment.backend.host}:${environment.backend.port}/api/v1/journal/link/asset/${asset.secret}?quality=${quality}`;
  }

  getDate(date: Date) {
    moment.locale('de');
    return moment(new Date(date)).format('dddd, DD.MM.YYYY HH:mm:ss');
  }

}
