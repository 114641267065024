import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {AlertModalService, AlertType} from "../alert-modal/alert-modal.service";
import validator from "validator";
import {LoaderService} from "../loader/loader.service";
import {AppService} from "../app.service";
import {AuthService} from "logbuch-client-sdk";

@Injectable()
export class VerifySessionService {
  visible: boolean = false;
  token: string = '';

  verifySuccess = new Subject<void>();
  verifyFail = new Subject<void>();

  constructor(private alertService: AlertModalService,
              private loader: LoaderService,
              private authService: AuthService,
              public appService: AppService) {
    //
  }

  verifySession() {
    return new Promise<void>(async (resolve, reject) => {
      this.loader.visibility = true;
      this.visible = true;
      await this.appService.checkAuth()
      if (!(await this.requestCode())) {
        this.loader.visibility = false;
        this.visible = false;
        this.token = '';
        reject();
      }
      this.loader.visibility = false;
      this.verifySuccess.subscribe(() => {
        this.loader.visibility = false;
        this.visible = false;
        this.token = '';
        resolve();
      });
      this.verifyFail.subscribe(() => {
        this.loader.visibility = false;
        this.visible = false;
        this.token = '';
        reject();
      });
    });
  }

  async checkToken() {
    try {
      this.loader.visibility = true;
      if (this.token != null && this.token.length === 6 && validator.isNumeric(this.token.toString())) {
        await this.authService.verifySession(this.token);
        this.verifySuccess.next();
      } else {
        this.alertService.show('Der Code ist ungültig, dieser muss aus 6 Ziffern bestehen.', 8000, AlertType.Error);
        this.loader.visibility = false;
      }
    } catch (e) {
      this.verifyFail.next();
    }
  }

  async requestCode(loader: boolean = false, useSMS: boolean = false) {
    if (loader) {
      this.loader.visibility = true;
    }

    try {
      await this.authService.requestVerify(useSMS ? 'sms' : 'email');
      return true;
    } catch (e) {
      this.verifyFail.next();
      return false;
    } finally {
      if (loader) {
        this.loader.visibility = false;
      }
    }
  }

}
