"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocketChannelEvents = void 0;
var SocketChannelEvents;
(function (SocketChannelEvents) {
  SocketChannelEvents["TrackRecords"] = "track:records";
  SocketChannelEvents["Track"] = "track:one";
  SocketChannelEvents["JournalUpdated"] = "journal:updated";
})(SocketChannelEvents || (exports.SocketChannelEvents = SocketChannelEvents = {}));