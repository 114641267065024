{
  "name": "vdr-client",
  "version": "1.0.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-docker": "ng build --configuration docker",
    "test": "ng test",
    "local": "ng serve --configuration local",
    "docker": "ng serve --configuration docker",
    "stable": "ng serve --configuration stable"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@simplewebauthn/browser": "^8.3.4",
    "@stripe/stripe-js": "^3.1.0",
    "@tweenjs/tween.js": "^23.1.1",
    "angularx-qrcode": "^18.0.2",
    "animate.css": "^4.1.1",
    "apple-mapkit-js": "^6.1.0",
    "base64-arraybuffer": "^1.0.2",
    "convert-grades": "^0.1.3",
    "crypto": "^1.0.1",
    "crypto-js": "^4.2.0",
    "dotenv": "^16.4.5",
    "font-awesome": "^4.7.0",
    "idb-keyval": "^6.2.1",
    "jquery": "^3.7.1",
    "lodash": "^4.17.21",
    "logbuch-client-sdk": "file:dist/sdk.tgz",
    "logbuch.misc": "file:dist/misc.tgz",
    "mapkit.js": "^0.0.1",
    "moment": "^2.30.1",
    "ol": "^8.2.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "socket.io-client": "^4.8.0",
    "tslib": "^2.3.0",
    "validator": "^13.11.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@types/apple-mapkit-js": "^5.50.0",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "~5.1.0",
    "@types/jquery": "^3.5.29",
    "@types/lodash": "^4.17.12",
    "@types/validator": "^13.11.8",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.4",
    "video.js": "^8.10.0"
  }
}
