import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {FloatLabelModule} from "primeng/floatlabel";
import {CascadeSelectModule} from "primeng/cascadeselect";
import moment from "moment";
import {DropdownModule} from "primeng/dropdown";
import {LoaderService} from "../loader/loader.service";
import {NgIf} from "@angular/common";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import {InputTextModule} from "primeng/inputtext";
import {RegistryService, TrackDataKey, TrackRecord} from "logbuch-client-sdk";

@Component({
  selector: 'app-track-record-details',
  standalone: true,
  imports: [
    FormsModule,
    CalendarModule,
    FloatLabelModule,
    CascadeSelectModule,
    DropdownModule,
    NgIf,
    InputTextModule
  ],
  templateUrl: './track-record-details.component.html',
  styleUrl: './track-record-details.component.scss'
})
export class TrackRecordDetailsComponent implements OnInit {

  @Input() trackRecord?: TrackRecord;
  @Input() availableRecords: TrackRecord[] = [];

  @Output() recordSelected = new EventEmitter<TrackRecord>();
  @Output() titleChanged = new EventEmitter<string>();

  selectedRecord?: TrackRecord;

  dataKeys: TrackDataKey[] = [];
  selectedDataKey?: TrackDataKey;

  @Input() title: string = '';
  value: string = '';

  constructor(private services: RegistryService,
              private loader: LoaderService,
              private alertService: AlertModalService) {
    //
  }

  async ngOnInit() {
    try {
      this.dataKeys = (await this.services.tracks.getTrackDataKeys())
        .filter(x => x.description !== '' && x.description !== null);
    } catch (e) {
      //
    }
  }

  formatDate(date: Date): string {
    return moment(new Date(date)).format('HH:mm');
  }

  selectRecord(record: TrackRecord) {
    this.selectedRecord = record;
    this.recordSelected.emit(record);
  }

  async selectDataKey(dataKey: TrackDataKey) {
    this.loader.visibility = true;
    try {
      if (this.trackRecord === undefined) {
        return;
      }
      const data = await this.services.tracks.getDataByKey(dataKey, this.trackRecord);
      this.value = data.value.toString();
      this.selectedDataKey = dataKey;
    } catch (e) {
      this.selectedDataKey = undefined;
      this.alertService.show('Zu dem ausgewählten Eintrag konnte kein entsprechender Datensatz gefunden werden.');
    } finally {
      this.loader.visibility = false;
    }
  }

}
