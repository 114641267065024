<div *ngIf="journal != null && journal!.journals.length <= 0" class="alert">
  <img src="assets/warning.svg">
  <div>Für diese Reise wurden noch keine Tageseinträge übernommen.</div>
</div>
<div *ngIf="journal != null && journal.journals != null && journal!.journals.length > 0" class="entities">
  <div *ngFor="let entity of journal!.journals; let i = index" #entities [id]="entity.id"
       class="entity animate__animated">
    <div class="entity-header">
      <div class="date-track">
        <i class="pi pi-calendar"></i>
        <p-floatLabel *ngIf="editMode">
          <p-calendar [(ngModel)]="entity.date" (ngModelChange)="entity.dateOnly = $event" dateFormat="dd.mm.yy" [iconDisplay]="'input'" [showIcon]="false"
                      (focusout)="appService.isDirty = true" placeholder="Datum"
                      id="date"></p-calendar>
        </p-floatLabel>
        <div *ngIf="!editMode" (click)="tryEdit()" [innerText]="getDate(entity)" class="date-inner"></div>
        <!-- <div *ngIf="isTrackAvailable(entity) && !editMode" class="track-symbol">
          <img (click)="selectTrack(entity)" src="assets/route.svg">
        </div>-->
      </div>
      <div *ngIf="!journal!.readonly" class="actions">
        <img (click)="entitySelected.emit(entity)" src="assets/cloud-computing.svg">
        <img *ngIf="appService.isDirty" (click)="leaveEdit()" src="assets/diskette.svg">
        <img *ngIf="!editMode" (click)="tryEdit()" src="assets/pen.svg">
        <i *ngIf="editMode && !appService.isDirty" (click)="editModeChange.emit(false)" class="pi pi-times"></i>
        <img *ngIf="!markedAsDeletion(entity)" (click)="deleteEntity(entity); editModeChange.emit(true);"
             src="assets/bin.svg">
        <img *ngIf="markedAsDeletion(entity)" (click)="undoDeletionOfEntity(entity); editModeChange.emit(true);"
             src="assets/undo.svg">
      </div>
    </div>
    <div class="text">
      <div *ngIf="!editMode" (click)="!journal!.readonly ? editModeChange.emit(true) : null"
           [innerHTML]="entity.text"></div>
      <p-editor *ngIf="editMode" [(ngModel)]="entity.text" [style]="{ height: '256px', 'font-size': '1.1rem' }"
                (ngModelChange)="appService.isDirty = true">
      </p-editor>
    </div>
    <div *ngIf="entity.assignments.length > 0" class="voice-entries">
      <label>Spracheinträge:</label>
      <div class="voice-entries-wrapper">
        <div *ngFor="let assignment of entity.assignments" class="voice-entry">
          <div class="main-row">
            <i class="pi pi-microphone"></i>
            <span *ngIf="!assignment.audioRecord?.isBackdated">{{ formatTime(assignment.audioRecord?.recordAt!) }}</span>
            <label [innerText]="assignment.audioRecord?.text"></label>
            <!-- <i class="pi pi-pencil"></i> -->
          </div>
          <small *ngIf="assignment.audioRecord?.isBackdated">
            Diese Sprachaufzeichnung wurde nachträglich angelegt.
          </small>
        </div>
      </div>
    </div>
    <app-journal-preview-entity-assets *ngIf="entity.isVisible" [entity]="entity" [readonly]="journal.readonly"
                                       (deleteAsset)="deleteAsset($event, entity)"/>
    <!-- <app-journal-entity-assets [assets]="entity.assets"
                               (onAssetClick)="previewService.isImage($event) ? selectImage($event, entity) : selectVideo($event)"
                               (onAssetDelete)="deleteAsset($event, entity)"
                               (onAssetPutThumbnail)="openFileDialogForThumbnail($event, entity)"/> -->
    <!-- <div *ngIf="filterAssets(entity).length > 0" class="pictures">
      <div class="pictures-wrapper">
        <div *ngFor="let asset of filterAssets(entity)" class="picture">
          <img [src]="asset.imageUrl" (click)="selectImage(asset, entity)"/>
        </div>
      </div>
    </div>
    <div *ngIf="filterAssets(entity, true).length > 0" class="movies">
      <div *ngFor="let movie of filterAssets(entity, true)" class="movie">
        <img (click)="selectVideo(movie)" src="assets/clapperboard.svg">
        <span>Film ab!</span>
      </div>
    </div> -->
  </div>
</div>

<div *ngIf="journal !== undefined && !journal.readonly" class="global-create-btn-wrapper">
  <div class="global-create-btn" (click)="newEntity()">
    <i class="pi pi-file-plus"></i>
    <span>Neuer Tag</span>
  </div>
</div>

<div *ngIf="selectTrackOfEntity !== undefined" (click)="selectTrackOfEntity = undefined" class="tracks-wrapper">
  <div class="tracks">
    <div class="title">
      Zu diesem Tag sind mehrere Aufzeichnungen vorhanden:
    </div>
    <div *ngFor="let track of getTracksByEntity(selectTrackOfEntity!)" (click)="trackSelected.emit(track)"
         class="track">
      <img src="assets/right-arrow.svg">
      <div>{{ track.name }}</div>
    </div>
  </div>
</div>

<input type="file" #fileInput style="display: none;" (change)="postThumbnail($event)">
