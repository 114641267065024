import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {RegistryService, UserDetails, SocketService} from "logbuch-client-sdk";
import {MapService} from "./map/map.service";

@Injectable()
export class AppService {

  navigate: Subject<void> = new Subject<void>();
  userLoaded: Subject<UserDetails> = new Subject<UserDetails>();
  checkAuthComplete: Subject<boolean> = new Subject<boolean>();
  currentUserChanged = new Subject<void>();
  searchChanged = new Subject<string>();

  isAuth = false;
  userDetails?: UserDetails;

  lastAuthCheck?: Date;
  authInProgress = false;

  isDirty = false;

  triggerUpdate: Subject<void> = new Subject<void>();

  stats: { journals: number, miles: number, tracks: number } = {
    journals: -1,
    miles: -1,
    tracks: -1
  };

  constructor(private services: RegistryService,
              private mapService: MapService,
              public websocket: SocketService) {
    this.triggerUpdate.subscribe(async () => {
      await this.updateStats();
    });
  }

  async checkAuth() {

    if (this.authInProgress) {
      return;
    }

    this.authInProgress = true;

    if (this.lastAuthCheck != undefined) {
      // Check Auth every 10 minutes
      const now = new Date();
      const diff = now.getTime() - this.lastAuthCheck.getTime();
      if (diff < 10 * 60 * 1000 && this.isAuth) {
        this.authInProgress = false;
        this.checkAuthComplete.next(this.isAuth);
        return;
      }
    }
    this.lastAuthCheck = new Date();

    try {
      await this.services.auth.checkSession();
      this.isAuth = true;
      const userDetails = await this.services.userDetails.getUserDetails();
      this.websocket.setupSocketConnection();
      await this.mapService.preloadMapKit();
      if (userDetails != undefined) {
        this.userDetails = userDetails;
        this.userLoaded.next(userDetails);
      }
    } catch (e) {
      this.isAuth = false;
    }

    this.checkAuthComplete.next(this.isAuth);
    this.authInProgress = false;
  }

  get hasUserGateways(): boolean {
    return this.userDetails != undefined
      && (this.userDetails.gateways.length > 0 || this.userDetails.permitted_gateways.length > 0);
  }

  async updateStats() {
    this.stats = await this.services.stats.getStats();
  }

}
