import {AfterViewInit, Component} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CrewEditorService, CrewMemberRole} from "./crew-editor.service";
import {CrewMember, RegistryService} from "logbuch-client-sdk";

@Component({
  selector: 'app-crew-editor',
  standalone: true,
  imports: [
    NgForOf,
    FormsModule,
    NgIf
  ],
  templateUrl: './crew-editor.component.html',
  styleUrl: './crew-editor.component.scss'
})
export class CrewEditorComponent implements AfterViewInit {

  crewMembers: CrewMember[] = [];

  get CrewMemberRole() {
    return CrewMemberRole;
  }

  get possibleMails() {
    const crewMember = this.crewMembers.find(m => m.name === this.service.obj.name);
    if (crewMember === undefined) {
      return this.crewMembers;
    } else {
      return this.crewMembers.filter(m => m.name === crewMember.name);
    }
  }

  constructor(public service: CrewEditorService, private services: RegistryService) {
    //
  }

  async ngAfterViewInit() {
    await this.loadCrewMembers();
    this.service.applied.subscribe(async () => {
      await this.loadCrewMembers();
    });
  }

  async loadCrewMembers() {
    // this.crewMembers = await this.services.journal.getCrewMembers();
  }

}
