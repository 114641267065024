import {AfterViewInit, Component} from '@angular/core';
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import {ButtonModule} from "primeng/button";
import {NgIf} from "@angular/common";
import {SelectButtonModule} from "primeng/selectbutton";
import {RadioButtonModule} from "primeng/radiobutton";
import {ProgressBarModule} from "primeng/progressbar";
import {FormsModule} from "@angular/forms";
import {UserDetails, MapType, AuthService, UserDetailsService} from "logbuch-client-sdk";

@Component({
  selector: 'app-settings-general-tab',
  standalone: true,
  imports: [
    ButtonModule,
    NgIf,
    SelectButtonModule,
    RadioButtonModule,
    ProgressBarModule,
    FormsModule
  ],
  templateUrl: './settings-general-tab.component.html',
  styleUrl: './settings-general-tab.component.scss'
})
export class SettingsGeneralTabComponent implements AfterViewInit {

  user?: UserDetails;

  get MapType() {
    return MapType;
  }

  constructor(private authService: AuthService,
              private alertService: AlertModalService,
              private userDetailsService: UserDetailsService,
              private loader: LoaderService) {
    //
  }

  async ngAfterViewInit() {
    try {
      this.user = await this.userDetailsService.getUserDetails() ?? undefined;
    } catch (e) {
      //
    }
  }

  async updateData() {

    if (this.user === undefined) {
      return;
    }

    this.loader.visibility = true;
    try {
      await this.userDetailsService.putUserDetails(this.user);
    } finally {
      this.loader.visibility = false;
    }
  }

}
